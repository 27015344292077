import { useEffect, useState } from 'react';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../../services/AuthenticationService';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab/Autocomplete';

import styles from './AddTag.module.css';

import { ITagAction } from '../../../models/ITagAction';
import { withStyles } from '@material-ui/core';

interface IAddTag {
  imageId: number;
  assignedTags: string[] | undefined;
  orgId: string;
  onAdd: Function;
}

const CustomAutocomplete = withStyles({
  tag: {
    backgroundColor: '#222',
    borderRadius: '0px',
    fontSize: 12,
    height: 18,
    position: 'relative',
    zIndex: 0,
    '& .MuiChip-label': {
      color: '#fff'
    }
  }
})(Autocomplete);

const AddTag = (props: IAddTag) => {
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9]{1,30}$/; //regex to validate
  const NO_OPT_FOUND = 'Tag already applied';
  const NEW_TAG_STR = ' (new tag)'; //new tag indicator text.

  const { imageId, assignedTags, orgId } = props;

  const [orgTags, setOrgTags] = useState<string[]>([]); // all tags available org wide
  const [availableTags, setAvailableTags] = useState<string[]>([]); // tags avaialble to display e.g. [org tag - assigned tags]
  const [showOptions, setShowOptions] = useState(false); // flag to decide display autocomplete suggestions
  const [tagError, setTagError] = useState<string>(); // to display error message in textbox
  const [key, setKey] = useState(1); // to flip the key and rerender component after a tag is assigned.

  const optFilter = createFilterOptions({ matchFrom: 'start' }); //mui option filter

  const getDistinctTags = async () => {
    try {
      let response: any = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + 'tag/distinctTags/' + orgId);
      if (response.status === 200) {
        let tags: string[] = response.data;
        setOrgTags(tags);
      } else {
        console.log('Failed to get tags for image = ' + response.status);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDistinctTags();
  }, [imageId]);

  useEffect(() => {
    let tags = orgTags;

    if (assignedTags && assignedTags.length > 0) {
      tags = tags.filter((t) => !assignedTags.includes(t));
    }

    setAvailableTags(tags);
  }, [assignedTags, orgTags]);

  const AddTagAsync = async (tag: string) => {
    try {
      let addObj: ITagAction = { imageIds: [imageId], tagNames: [tag], organisationId: parseInt(orgId) };

      let response: any = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + 'tag/addTags', addObj);
      if (response.status === 200) {
        props.onAdd(tag);
        setKey(Math.random());
      } else {
        console.log('Failed to get tags for image = ' + response.status);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onRenderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        style={{ fontSize: '8px' }}
        size='small'
        error={tagError ? true : false}
        InputProps={{ ...params.InputProps, style: { fontSize: '12px' } }}
        FormHelperTextProps={{ style: { fontSize: '12px' } }}
        helperText={tagError ? tagError : ''}
        onKeyDown={(event) => {
          if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
            event.preventDefault();
          }
        }}
      />
    );
  };

  const onTextChange = (e: any, value: string) => {
    setShowOptions(value.length > 0);
  };

  const formatOption = (option: any) => {
    if (option) {
      option = option.toLowerCase();

      if (option.includes(NEW_TAG_STR)) {
        return option.replaceAll(NEW_TAG_STR, '');
      }
      return option;
    }
    return option;
  };

  const handleAutoCompleteChange = (event: any, newValue: any) => {
    let tag = formatOption(newValue);

    setTagError('');
    if (tag) {
      if (tag.length > 30) {
        setTagError('Max length is 30 characters');
      } else {
        if (!assignedTags?.filter((x) => x.toLowerCase()).includes(tag.toLowerCase())) {
          AddTagAsync(tag);
        }
      }
    }
  };

  return (
    <div className={styles.addContainer}>
      <CustomAutocomplete
        key={key}
        freeSolo
        handleHomeEndKeys
        id='add-tag'
        options={availableTags}
        open={showOptions}
        renderInput={onRenderInput}
        style={{ width: 300, padding: 4 }}
        onInputChange={onTextChange}
        onClose={() => setShowOptions(false)}
        onChange={handleAutoCompleteChange}
        filterOptions={(options: any[], params: any) => {
          const filtered = optFilter(options, params);

          let typedOption = params.inputValue;

          if (typedOption.length > 0) {
            //if typed tag already exists for image
            if (assignedTags?.filter((x) => x.toLowerCase())?.includes(typedOption.toLowerCase())) {
              return [NO_OPT_FOUND];
            }

            // Suggest the creation of a new value, if typed tag is not available org wide
            if (typedOption !== '' && !availableTags.filter((x) => x.toLowerCase()).includes(typedOption.toLowerCase())) {
              filtered.push(typedOption + NEW_TAG_STR);
            }

            return filtered;
          }
          return []; // something is wrong, we don't show any options without user typing anything.
        }}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return formatOption(option);
          }
        }}
        renderOption={(option: any) => {
          if (option.includes(NEW_TAG_STR)) {
            let strs = option.split(' ');
            return (
              <span style={{ fontSize: '12px', color: '#efad4b' }}>
                {strs[0]} <i>{strs[1] + ' ' + strs[2]}</i>
              </span>
            );
          } else {
            return <span style={{ fontSize: '12px' }}>{option}</span>;
          }
        }}
        getOptionDisabled={(option) => option === NO_OPT_FOUND}
      />
    </div>
  );
};

export default AddTag;
