import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core/";
import NearMeIcon from '@material-ui/icons/NearMe';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import imageStatusDisabled from "../../images/power_settings_new_24px_disabled.png";
import imageStatusEnabled from "../../images/power_settings_new_24px.png";
import imageStatusActive from "../../images/power_settings_new_24px_active.png";
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import styles from "./ToolBar.module.css";
import IconButton from '@material-ui/core/IconButton';

interface IToolBarProps {
    isSelectModeEnabled: boolean;
    selectedImagesCount: number,
    isLinkModeEnabled: boolean;
    enableLinkModeButton: boolean,
    onIsLinkModeEnabledChanged: Function;
    onUnlink: Function;
    onIsSelectModeEnabledChanged: Function;
    showImageStatusButton: boolean;
    showLinkUnlinkButton: boolean;
    onShowImageStatusMenu: Function;
    showImageAssigneeButtons: boolean;
    onShowImageAssignMenu: Function;
    onUnassign: Function;
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'rgba(147, 186, 245, 0.15)',
       "&:hover": {
            background: 'rgba(147, 186, 245, 0.15)',
        },           
    },
    label: {
        color: '#95C5E7',
        "&:hover": {
            color: '#F9FCFF',
        },
    }
}));

export function ToolBar(props: IToolBarProps) {
    const selectedStyles = useStyles();

    return  <div>
        <Box display="flex" alignItems="center" justifyContent="center">
            <div className={[styles.listContainer,  props.isSelectModeEnabled ? styles.listContainerSelected : ''].join(' ') }>
                <div className={styles.listItem} title='Select mode'>
                    <IconButton
                        className={styles.selectIcon}
                        classes={props.isSelectModeEnabled ? selectedStyles : undefined}
                        aria-controls="Select-Mode"
                        onClick={() => props.onIsSelectModeEnabledChanged(!props.isSelectModeEnabled)} >
                        <NearMeIcon />
                    </IconButton>
                </div>
                {props.showLinkUnlinkButton &&
                    <div className={styles.listItem} title='Link mode'>
                        <IconButton
                            classes={props.isLinkModeEnabled ? selectedStyles : undefined}
                            aria-controls="Link-Mode"
                            disabled={!props.enableLinkModeButton || props.selectedImagesCount === 0}
                            onClick={() => props.onIsLinkModeEnabledChanged(!props.isLinkModeEnabled)} >
                            <LinkIcon />
                        </IconButton>
                    </div>
                }
                {props.showLinkUnlinkButton &&
                    <div className={styles.listItem} title='UnLink mode'>
                        <IconButton
                            aria-controls="UnLink-Mode"
                            disabled={props.selectedImagesCount === 0}
                            onClick={() => props.onUnlink()} >
                            <LinkOffIcon />
                        </IconButton>
                    </div>
                }
                { props.showImageStatusButton && 
                    <div className={styles.listItem} title='Change image status' >
                        <IconButton
                            disabled={props.selectedImagesCount === 0 }
                            aria-controls="Change-image-status"
                            onClick={(e) => props.onShowImageStatusMenu(e)} >
                            <img width="27px" src={ (props.selectedImagesCount > 0) ? imageStatusEnabled : imageStatusDisabled } onMouseOver={ (e:any) => { e.currentTarget.src=imageStatusActive } }  onMouseLeave={ (e:any) => { e.currentTarget.src= (!props.isLinkModeEnabled && props.selectedImagesCount > 0) ? imageStatusEnabled : imageStatusDisabled  } } />
                        </IconButton>
                    </div>
                }
                { props.showImageAssigneeButtons && 
                    <div className={styles.listItem} title='Assign image to user' >
                        <IconButton
                            disabled={props.selectedImagesCount === 0 }
                            aria-controls="Assign-image"
                            onClick={(e) => props.onShowImageAssignMenu(e)} >
                            <PersonAddIcon />
                        </IconButton>
                    </div>
                }
                { props.showImageAssigneeButtons &&  
                    <div className={styles.listItem} title='Unassign image' >
                        <IconButton
                            disabled={props.selectedImagesCount === 0 }
                            aria-controls="Unassign-image"
                            onClick={(e) => props.onUnassign(e)} >
                            <PersonAddDisabledIcon />
                        </IconButton>
                    </div>
                }
            </div>
        </Box>
      </div>
}
