import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { IGeneralConfirmationSnackbarConfig } from '../../../models/IGeneralConfirmationSnackbarConfig';

interface IGeneralConfirmationSnackBarProps {
    config: IGeneralConfirmationSnackbarConfig;
    onCancel: Function;
    onConfirm: Function;
}

export function GeneralConfirmationSnackBar(props: IGeneralConfirmationSnackBarProps) {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        anchorOriginStyle: {
            top: props.config.top,
            right: props.config.right
        }

    }));

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
   
    const handleCancel = () => {
        setOpen(false);
        props.onCancel();
    };

    return (
        <div >
            <React.Fragment>
                <Snackbar
                    classes={{ root: classes.root, anchorOriginTopRight: classes.anchorOriginStyle }}
                    open={open}
                    message={props.config.message}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    action={
                        <React.Fragment>
                            <IconButton
                                onClick={handleCancel}
                                style={{ height: 17, width: 17, marginRight: 6 }}>
                                <ClearIcon style={{ fontSize: 20, color: 'rgb(255, 153, 153)' }} />
                            </IconButton>
                            <IconButton
                                onClick={() => props.onConfirm()}
                                style={{
                                    height: 17, width: 17, marginLeft: 4}}>
                                <DoneIcon style={{
                                    fontSize: 20, color: 'rgb(174, 213, 129)' }} />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                <div style={{width: '100%', height: '100%', position: 'fixed', top:0,left:0, backgroundColor: 'rgba(0,0,0,0.5)'}}></div>
             </React.Fragment>
        </div>
    );
}
