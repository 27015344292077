import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { useEffect, useState } from 'react';
import { Box, Chip, Paper, Tooltip } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import { fetchWithAuthorisationHeader, getAuthorizationData, postWithAuthorisationHeader } from '../../services/AuthenticationService';

import styles from './TagViewer.module.css';
import { withStyles } from '@material-ui/core/styles';
import { ITagAction } from '../../models/ITagAction';
import AddTag from './AddTag/AddTag';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: '#F7B500',
    color: '#000000',
    marginLeft: '4px',
    marginTop: '4px'
  }
})(Badge);

const SelectedIconButton = withStyles({
  root: {
    height: 20,
    width: 20,
    background: 'rgba(147, 186, 245, 0.15)',
    '&:hover': {
      background: 'rgba(147, 186, 245, 0.15)'
    }
  },
  label: {
    color: '#95C5E7',
    '&:hover': {
      color: '#F9FCFF'
    }
  }
})(IconButton);

const NormalIconButton = withStyles({
  root: {
    height: 20,
    width: 20
  }
})(IconButton);

interface ITagViewerProps {
  imageId: number;
  canManageTags: boolean;
}

export const TagViewer = (props: ITagViewerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tagData, setTagData] = useState<string[]>([]);
  const [showAdd, setShowAdd] = useState(false);

  const tagLen = tagData?.length ?? 0;
  let chips: JSX.Element[] = [];

  let width = 0;

  let authData = getAuthorizationData();

  const fetchTags = async (sessionImageId: number) => {
    try {
      let response: any = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + 'tag/getTags/' + sessionImageId);
      if (response.status === 200) {
        let tags: string[] = JSON.parse(response.data);
        setTagData(tags);
      } else {
        console.log('Failed to get tags for image = ' + response.status);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTags(props.imageId);
    setShowAdd(false);
  }, [props.imageId]);

  const handleDelete = async (tag: string) => {
    let deleteObj: ITagAction = { imageIds: [props.imageId], tagNames: [tag], organisationId: parseInt(authData.organisationId)};

    try {
      let response = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + 'tag/deleteTags', deleteObj);
      if (response.status === 200) {
        setTagData((tags) => tags?.filter((t) => t !== tag));
      } else {
        console.log('Failed to delete tag for image = ' + response.status);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (tagLen > 0) {
    tagData?.sort().forEach((t: string, k: number) => {
      chips.push(
        <Chip
          key={k}
          style={{ margin: 3, color: '#fff', borderColor: 'none', backgroundColor: '#222', borderRadius: 0, fontSize: 12, height: 18 }}
          label={t}
          size='small'
          deleteIcon={props.canManageTags ? <ClearIcon /> : undefined}
          onDelete={
            props.canManageTags
              ? () => {
                  handleDelete(t);
                }
              : undefined
          }
        />
      );
    });
  }

  //Add default add tag chip only if tags are less than 30
  if (tagLen < 30 && props.canManageTags) {
    chips.push(
      <Chip
        key={100}
        label={''}
        style={{ margin: 3, color: '#111', borderColor: 'none', backgroundColor: '#efad4b', borderRadius: 0, fontSize: 12, height: 18 }}
        size='small'
        icon={<AddIcon style={{ marginRight: -12 }} />}
        onClick={() => {
          setShowAdd(!showAdd);
        }}
      />
    );
  }

  if (chips.length <= 3) {
    width = 196;
  } else {
    width = 400;
  }

  return (
    <>
      <StyledBadge
        max={30}
        color='primary'
        badgeContent={tagLen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <div className={styles.tagBtn} style={{ padding: 4 }}>
            <Tooltip title='View Tags'>
              {isOpen ? (
                <SelectedIconButton
                  onClick={() => {
                    setIsOpen(false);
                  }}>
                  <LocalOfferIcon style={{ fontSize: 20 }} />
                </SelectedIconButton>
              ) : (
                <NormalIconButton
                  onClick={() => {
                    setIsOpen(true);
                  }}>
                  <LocalOfferIcon style={{ fontSize: 20 }} />
                </NormalIconButton>
              )}
            </Tooltip>
          </div>
        </Box>
      </StyledBadge>
      {isOpen ? (
        <div className={styles.tagContainer} style={{ minWidth: width }}>
          {chips}
        </div>
      ) : (
        <></>
      )}
      {props.canManageTags && isOpen && showAdd && tagLen < 30 && (
        <AddTag
          imageId={props.imageId}
          assignedTags={tagData}
          orgId={authData.organisationId}
          onAdd={(tag: string) => {
            if (tag) {
              setTagData([...tagData, tag]);
            }
          }}
        />
      )}
    </>
  );
};
