import * as React from 'react';

import { IDZIViewerState } from '../../../models/IDZIViewerState';
import { IImage } from '../../../models/IImage';
import { IPropertyValue } from '../../../models/IPropertyValue';
import { IRect } from '../../../models/IRect';
import { IDefect } from '../../../models/IDefect';
import { IImageStatus } from '../../../models/IImageStatus';
import { IUser } from '../../../models/IUser';

import { ComponentPropertySyncConfig, IComponentPropertySyncConfig } from '../ComponentPropertySyncConfig'

import { getPropertiesThatHaveChanged, updatePropertiesFromUpdates, getPropertyValuesFromProps } from '../StateSyncCommon';
import { IImageDefectCount } from '../../../models/IImageDefectCount';
import { IThreeDViewerConfig } from '../../../models/IThreeDViewerConfig';
import { IThreeDViewerState } from '../../../models/IThreeDViewerState';
import { IMapLayer } from '../../../models/IMapLayer';
import { ICoordinate } from '../../../models/ICoordinate';

interface IPopupInfo {
    componentType: any;
    windowId: string;
}

interface IPopupWindow {
    window: any;
    windowId: string;
    config: IComponentPropertySyncConfig;
    initialised: boolean;
}


interface IPopupWindowManagerAndPostMessageStateSyncProps {
    isDziViewerPoppedOut: boolean;
    dziViewerPopupState: IDZIViewerState | null;
    dziViewerWindowRect: IRect | null;
    windowId: string;
    selectedDziImage: IImage | null;
    defects: IDefect[];
    userPermissions: string[];
    onStateChanged: Function;
    imageStatuses: IImageStatus[];
    organisationUsers: IUser[];
    isDefectModeEnabled: boolean;
    updatedImageIds: number[] | null;
    imageDefectCount : IImageDefectCount[];
    isThreeDViewerPoppedOut: boolean;
    threeDViewerWindowRect: IRect | null;
    threeDViewerConfig: IThreeDViewerConfig | null;
    threeDViewerPopupState: IThreeDViewerState | null;
    mapDataLayers: IMapLayer[][] | null;
    flyToLocation: ICoordinate | null;
};

interface IPopupWindowManagerAndPostMessageStateSyncState {

}


export class PopupWindowManagerAndPostMessageStateSync extends React.Component<IPopupWindowManagerAndPostMessageStateSyncProps, IPopupWindowManagerAndPostMessageStateSyncState> {
    popupWindows: IPopupWindow[] = [];
    propertyValues: IPropertyValue[] = [];

    constructor(props: IPopupWindowManagerAndPostMessageStateSyncProps) {
        super(props);

        // Handle messages from child windows
        let this_ = this;
        window.addEventListener("message", function (event) {
            try {

                if (event.origin.split(":", 2).join(":") === window.location.origin.split(":", 2).join(":")) {
                    this_.processMessage(event);
                }
            } catch (e) {
                // We dont care about this error as non vaa messages can cause exceptions
            }
        }
            , false);

        window.addEventListener("unload", function (event) {
            this_.closeAllPopups();
        }
            , false);


        this.propertyValues = getPropertyValuesFromProps(this.props);
    }

    processMessage(event: any) {
        let message = event.data;
        let data = message.package ? JSON.parse(message.package) : null;

        if (message.action == null || message.action.indexOf('VAA') !== 0 || this.popupWindows.length === 0)
            return;

        try {
            //console.log('State Sync received message ' + message.action + ' from window ' + message.sender);

            switch (message.action) {
                case "VAA_POPUP_INITIALISED":
                    this.handlePopupInitialised(data);
                    break;

                case "VAA_POPUP_WINDOW_STATE_UPDATE":
                    this.handlePopupStateUpdate(message.sender, data);
                    break;

                case "VAA_POPUP_CLOSED":
                    this.handlePopupClosed(data.windowId);
                    break;

            }
        } catch (e) {
            console.log('ERROR "' + e + '"  in handling message: ' + message.action + ' package: ' + message.package);
        }
    }

    componentDidUpdate(prevProps: IPopupWindowManagerAndPostMessageStateSyncProps) {

        // Propagate property changes to popup windows

        // Certain property changes are used to trigger opening of a new popup window
        if (this.props.isDziViewerPoppedOut && !prevProps.isDziViewerPoppedOut && this.props.dziViewerWindowRect != null) {
            //Create a dzi viewer window
            this.openNewPopupWindow('DZIViewer', '/dziviewer', this.props.dziViewerWindowRect);
        }
        else if (!this.props.isDziViewerPoppedOut && prevProps.isDziViewerPoppedOut) {
            //Close the dzi viewer window if open      
            this.closePopupWindow('DZIViewer');
        }
        else if (this.props.isThreeDViewerPoppedOut && !prevProps.isThreeDViewerPoppedOut && this.props.threeDViewerWindowRect != null) {
            //Create a three d viewer window
            this.openNewPopupWindow('ThreeDViewer', '/threedviewer', this.props.threeDViewerWindowRect);
        }
        else if (!this.props.isThreeDViewerPoppedOut && prevProps.isThreeDViewerPoppedOut) {
            //Close the three d viewer window if open      
            this.closePopupWindow('ThreeDViewer');
        }

        let newPropertyValues = getPropertyValuesFromProps(this.props);

        let updatedProperties = getPropertiesThatHaveChanged(this.propertyValues, newPropertyValues);
        this.propertyValues = newPropertyValues;

        // Send only configured properties to initialised windows
        // Each type of popup can have different properties
        for (let popup of this.popupWindows.filter(p => p.initialised)) {

            let updatesToSend = updatedProperties.filter(property => popup.config.propertiesToSync.includes(property.name));

            if (updatesToSend.length > 0) {
                this.sendMessageToPopupWindow(popup.window, 'VAA_PARENT_WINDOW_STATE_UPDATE', updatesToSend);
            }
        }
    }

    // Opens a new popup window and gets the configuration of what properties to sync
    openNewPopupWindow(componentType: string, url: string, windowRect: IRect) {

        let config = ComponentPropertySyncConfig.find(c => c.componentType === componentType);
        if (config == null)
            return;

        let existingWindow = this.popupWindows.find(p => p.config.componentType === componentType);
        if (existingWindow)
            this.popupWindows = this.popupWindows.filter(p => p != existingWindow);

        let params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=' + windowRect.width + ',height=' + windowRect.height + ',left=' + windowRect.left + ',top=' + windowRect.top;

        let newWindow = window.open(url, componentType, params);

        this.popupWindows.push({ window: newWindow, windowId: '', initialised: false, config: config });
    }

    // Closes a popup window
    closePopupWindow(componentType: string) {

        let popupWindow = this.popupWindows.find(p => p.config.componentType === componentType);
        if (popupWindow) {
            this.popupWindows = this.popupWindows.filter(p => p != popupWindow);
            popupWindow.window.close();
        }
    }

    // When a popup initialises send it all the properties it needs for its component
    handlePopupInitialised(popupInfo: IPopupInfo) {

        let popup = this.popupWindows.find(p => p.config.componentType === popupInfo.componentType);

        if (popup == null)
            return;

        popup.initialised = true;
        popup.windowId = popupInfo.windowId;

        let propsToSync = popup.config.propertiesToSync;

        let propertyValuesForPopup = this.propertyValues.filter(property => propsToSync.includes(property.name));
        this.sendMessageToPopupWindow(popup.window, 'VAA_PARENT_WINDOW_STATE_UPDATE', propertyValuesForPopup);
    }

    // A popup has closed.  Emit a state update to let the parent container know
    handlePopupClosed(windowId: string) {

        let popup = this.popupWindows.find(p => p.windowId === windowId);
        if (popup) {
            this.popupWindows = this.popupWindows.filter(p => p != popup);

            if (popup.config.componentType === 'DZIViewer') {
                this.props.onStateChanged({ isDziViewerPoppedOut: false });
                this.props.onStateChanged({ selectedDziImage: null });
            }
            else if (popup.config.componentType === 'ThreeDViewer') {
                this.props.onStateChanged({ isThreeDViewerPoppedOut: false });
            }
        }
    }

    // Property updates have been sent from a popup window
    handlePopupStateUpdate(windowId: string, updatedProperties: IPropertyValue[]) {

        // Send the updates to other popup windows
        let otherPopups = this.popupWindows.filter(p => p.windowId !== windowId && p.initialised);
        for (let popup of otherPopups) {

            // Only send configured updates
            let updatesToSend = updatedProperties.filter(prop => popup.config.propertiesToSync.includes(prop.name));
            if (updatesToSend.length > 0) {
                this.sendMessageToPopupWindow(popup.window, 'VAA_PARENT_WINDOW_STATE_UPDATE', updatesToSend);
            }
        }

        // Set the updates on the parent container by emitting an object that will get updated in the container state
        let propertyUpdates = getPropertiesThatHaveChanged(this.propertyValues, updatedProperties);
        if (propertyUpdates.length === 0)
            return;

        let stateUpdate: any = {};

        for (let propertyUpdate of propertyUpdates) {
            stateUpdate[propertyUpdate.name] = propertyUpdate.value;
        }

        updatePropertiesFromUpdates(this.propertyValues, propertyUpdates);

        this.props.onStateChanged(stateUpdate);
    }

    sendMessageToPopupWindow(popupWindow: any, action: any, data: any) {

        let msg = {
            action: action,
            sender: this.props.windowId,
            package: JSON.stringify(data)
        };

        popupWindow.postMessage(msg, "*");
    }

    closeAllPopups() {

        for (let popup of this.popupWindows) {
            popup.window.close();
        }
    }

    render() {
        return null; //no ui
    }
}