import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import * as olControl from 'ol/control';
import ImageLayer from 'ol/layer/Image';
import { transform, transformExtent, fromLonLat } from 'ol/proj';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import TileWMS from 'ol/source/TileWMS';
import WKT from 'ol/format/WKT';
import ImageWMS from 'ol/source/ImageWMS';
import { easeOut } from 'ol/easing';
import { Circle as CircleStyle, RegularShape } from 'ol/style';
import { getVectorContext } from 'ol/render';
import { unByKey } from 'ol/Observable';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import ScaleLine from 'ol/control/ScaleLine';
import {buffer} from 'ol/extent';
import { DragBox, Select } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { fromExtent } from 'ol/geom/Polygon';
import {collection} from 'ol/Collection';

import './OpenLayers.css';
import { access } from 'fs';
import SelectionIcon from '../../images/selection-icon.png';

export default class OpenLayersMap {

    constructor(mapOptions, baseMapLayers, mapPlaceHolder, callBackHandler) {
        this.DEFAULT_ZOOM = 16;
        this.PAN_ANIMATE_SPEED = 800;
        this.ZOOM_ANIMATE_SPEED = 1000;
        this.dataProjection = mapOptions['dataProjection'];
        this.mapProjection = mapOptions['mapProjection'];
        this.wmsApiUrl = mapOptions['wmsApiUrl'];
        this.isDebugging = this.wmsApiUrl.indexOf('localhost') !== -1;
        this.mapBoxBaseUrl = mapOptions['mapBoxBaseUrl'];
        this.openTopoMapBaseUrl = mapOptions['openTopoMapBaseUrl'];
        this.baseMapLayers = baseMapLayers;
        this.mapPlaceHolder = mapPlaceHolder;
        this.zoom = mapOptions['zoom'] ? mapOptions['zoom'] : this.DEFAULT_ZOOM;
        this.accessToken = null;
        this.accessTokenExpiryTime = null;
        this.tokenRefreshRequested = false;
        this.currentBaseLayer = null; 
        this.pulseTimeout = 1000;
        this.callBackHandler = callBackHandler;
        this.maxZoom = 23;
        this.map = null;
        this.extent = null;
        this.tileGutter = 5;
        this.imgGutter = 100;

        this.INCHES_PER_UNIT = { 
            'm': 39.37,
            'dd': 4374754
        };
        this.DOTS_PER_INCH = 72;

        this.map = new Map({
            target: this.mapPlaceHolder,
            renderer: 'canvas',
            view: new View({
                center: transform([144.96134128460633, -37.824446531440493], this.dataProjection, this.mapProjection),
                zoom: this.zoom,
                maxZoom: this.maxZoom
            }),
            controls: new olControl.defaults({
                rotate: true,
                zoom: true,
                attribution: false
            })

        });

       this.map.addControl(new ScaleLine());
       
        for (var index = 0; index < this.baseMapLayers.length; index++) {
            var baseLayer = this.baseMapLayers[index];
            var baseLayerUrl = baseLayer.source === 'opentopomap'
                ? this.openTopoMapBaseUrl
                : this.mapBoxBaseUrl.replace("{baselayerName}", baseLayer.baseLayerName);


            var rasterLayer = new TileLayer({
                title: 'BaseLayer_' + baseLayer.baseLayerTitle,
                type: 'base',
                visible: baseLayer.active,
                source: new XYZ({
                    url: baseLayerUrl
                })
            });

            if (baseLayer.active) this.currentBaseLayer = rasterLayer;

            this.map.addLayer(rasterLayer);

        }

        this.mapResolution = this.map.getView().getResolution();

        // a DragBox interaction used to select features by drawing boxes
        this.rightClickDragBox = new DragBox({
            condition: (event) => { return (event.originalEvent.button == 2) } //enable drag box if right click 
        });

        this.dragBox = new DragBox({
            condition: platformModifierKeyOnly
        });

        this.map.addInteraction(this.dragBox);
        this.map.addInteraction(this.rightClickDragBox);
        this.dragBox.setActive(false);
        this.rightClickDragBox.setActive(true);

        //////////// Setup event handlers

        var this_ = this;

        this_.dragBox.on('boxend', function () {
            this_.map.getLayers().forEach(function (layer) {
                if (layer.get('name') === 'ImageSearchResult') {
                    var extent = this_.dragBox.getGeometry().getExtent();
                    var format = new WKT();
                    var wkt = format.writeGeometry(fromExtent(extent), {
                        dataProjection: this_.dataProjection,
                        featureProjection: this_.mapProjection
                    });

                    if (this_.callBackHandler) {
                        this_.callBackHandler.onDragBoxEnd({
                           wkt: wkt
                        });
                    }
                }
            });
        });

        this_.rightClickDragBox.on('boxend', function () {
            this_.map.getLayers().forEach(function (layer) {
                if (layer.get('name') === 'ImageSearchResult') {
                    var extent = this_.rightClickDragBox.getGeometry().getExtent();
                    var format = new WKT();
                    var wkt = format.writeGeometry(fromExtent(extent), {
                        dataProjection: this_.dataProjection,
                        featureProjection: this_.mapProjection
                    });

                    if (this_.callBackHandler) {
                        this_.callBackHandler.onDragBoxEnd({
                           wkt: wkt
                        });
                    }
                }
            });
        });

        this.map.on('moveend', function (evt) {

            var map = evt.map;

            var centre = map.getView().getCenter();
            var t = transform([centre[0], centre[1]], this_.mapProjection, this_.dataProjection);

            if (this_.callBackHandler) {
                this_.callBackHandler.onMapMoveEnd(
                    {
                        longitude: t[0],
                        latitude: t[1]
                    },
                    map.getView().getZoom()
                );
            }
        });
        this.map.on('singleclick', function (evt) {

            var locationCoordiantes = transform(evt.coordinate, this_.mapProjection, this_.dataProjection);
            var currentZoom = this_.map.getView().getZoom();

            var extent = this_.map.getView().calculateExtent(this_.map.getSize());
            var boundingExtent = transformExtent(extent, this_.mapProjection, this_.dataProjection);

            // Get feature info urls for visible layers
            var viewResolution = this_.map.getView().getResolution();
            var coord = evt.coordinate;

            let featureInfoUrls = [];

            this_.map.getLayers().forEach(function (layer) {

              if (layer.getVisible() && layer.getSource() != null && layer.getSource().getFeatureInfoUrl != null) {
                  
                  let featureInfoUrl = layer.getSource().getFeatureInfoUrl(coord, viewResolution, 'EPSG:3857', {
                          'INFO_FORMAT': 'text/html',
                          'FEATURE_COUNT': '100'
                      });

                   featureInfoUrls.push({layer: layer.get('name'), url: featureInfoUrl });
                } 
            });

            if (this_.callBackHandler) {
                this_.callBackHandler.onMapSingleClick({
                    longitude: locationCoordiantes[0],
                    latitude: locationCoordiantes[1],
                    screenX: evt.originalEvent.clientX,
                    screenY: evt.originalEvent.clientY,
                    layerX: evt.originalEvent.layerX,
                    layerY: evt.originalEvent.layerY,
                    latLongEpsg: 4283,
                    zoom: currentZoom,
                    extent: boundingExtent,
                    visbleLayersFeatureInfoUrls: featureInfoUrls
                });
            }
        });
        this.map.on('movestart', function(e) {
            if (this_.callBackHandler) {
                this_.callBackHandler.onMapMoveStart(e);
            }
        })
        this.map.on('pointermove', function (e) {
            if (this_.callBackHandler) {
                this_.callBackHandler.onMapPointerMove(e);
            }
        });

        this.map.getView().setZoom(this.zoom);
    }

    getScaleFromResolution = (resolution, units, opt_round) => {
        var scale = this.INCHES_PER_UNIT[units] * this.DOTS_PER_INCH * resolution;
        if (opt_round) {
            scale = Math.round(scale);
        }

        return scale;
    }

    setCentre = (centre) => {
        this.map.getView().setCenter(transform([centre.longitude, centre.latitude], this.dataProjection, "EPSG:900913"));
    }

    getCentre = () => {
        let centre = this.map.getView().getCenter();
        let centreLatLon = transform([centre[0], centre[1]], this.mapProjection, this.dataProjection);
        return { "longitude": centreLatLon[0], "latitude": centreLatLon[1] };
    }

    // zoomLevel is optional. if it is null or undefined, should set by default zoom
    setZoom = (zoomLevel) => {
        let zoom = zoomLevel ? zoomLevel : this.zoom;
        this.map.getView().setZoom(zoom);

    }

    

    removeWKT = (name) => {
        var this_ = this;
        var theLayer;

        this_.map.getLayers().forEach(function (layer) {
            if (layer.get('name') === name) {
                theLayer = layer;
            }
        });

        if (theLayer != null) {
            this_.map.removeLayer(theLayer);
            this_.map.render();
        }
    }

    displayWKT = (fovData) => {
        var this_ = this;
        var foundLayer = false;

        var layerName = fovData.layerName;
        var wkt = fovData.wkt;
        var strokeColor = fovData.strokeColor;
        var fillColor = fovData.fillColor;

        // Avoid creating the layer each time
        var format = new WKT();
        var fovFeature = format.readFeature(wkt);
        fovFeature.getGeometry().transform(this_.dataProjection, this_.mapProjection);

        var styles = [
            new Style({
                stroke: new Stroke({
                    color: strokeColor,
                    width: 2
                }),
                fill: new Fill({
                    color: fillColor
                })
            })
        ];

        this_.map.getLayers().forEach(function (layer) {
            if (layer.get('name') === layerName) {
                var source = layer.getSource();
                source.clear();
                source.addFeatures([fovFeature]); // Update the layer source only
                foundLayer = true;
            }
        });

        // Create if first time
        if (foundLayer === false) {

            var vector = new VectorLayer({
                name: layerName,
                zIndex: this.zIndexMapLayers[layerName],
                style: styles,
                source: new VectorSource({
                    features: [fovFeature]
                })
            });

            this_.map.addLayer(vector);
        }

    }

    doesLayerExist = (layerName) => {

        var foundLayer = false;

        this.map.getLayers().forEach(function (layer) {

            if (layer.get('name') === layerName) {
                foundLayer = true;
                
            }
        });

        return foundLayer;
    }

    showLayer = (name = null, isVisible = true) => {
        if (name==null) return;

        this.map.getLayers().forEach(l => {
            if (l.get('name') === name) {
                l.setVisible(isVisible);
                
            }
        })
        
    }

    createOrthographicLayer(layerName,layerUrl,active, min, max, zOrder)
    {
        var rasterLayer = new TileLayer({
          title: layerName,
          name: layerName,
          zIndex: zOrder,
          visible: active,
          minZoom: min,
          maxZoom: max,
          source: new XYZ({
            attributions: '',
            url: process.env.REACT_APP_VAA_API_URL + layerUrl,
            tileLoadFunction: this.orthographicTileLoader,
            tileSize: [256, 256],
          }),
        });
        this.map.addLayer(rasterLayer);
    }

    createWMSLayer = (layerName, filterKeys, accessToken, tiled, zIndex, minZoom = 1, maxZoom = 23) => {
        var this_ = this;
        var foundLayer = false;

        this.accessToken = accessToken;

        tiled = typeof tiled == 'undefined' ? true : tiled;

        foundLayer = this.doesLayerExist(layerName);

        // Create if not
        if (!foundLayer) {

            var params = {
                'LAYERS': layerName//, // Map file name
                // 'access_token': accessToken
            };

            //Add filters to the params
            for (const key in filterKeys) {
                let value = filterKeys[key];

                params[key] = value;
                if (key === 'featureLayerIds') {
                    params['t'] = Date.now();
                }
            }

            let olWMSLayer;
            if (tiled) {
                olWMSLayer = new TileLayer({
                    name: layerName, // OL layer name
                    zIndex: zIndex,
                    minZoom: minZoom,
                    maxZoom: maxZoom,
                    source: new TileWMS({
                        url: this_.wmsApiUrl,
                        gutter: this.tileGutter,
                        params: params,
                        tileLoadFunction: this.customWMSLoader

                    })

                });
            }
            else {
                olWMSLayer = new ImageLayer({
                    name: layerName, // OL layer name
                    zIndex: zIndex,
                    minZoom: minZoom,
                    maxZoom: maxZoom,
                    source: new ImageWMS({
                        url: this_.wmsApiUrl,
                        gutter: this.imgGutter,
                        params: params,
                        imageLoadFunction: this.customWMSLoader
                    })

                });
            }

            this_.map.addLayer(olWMSLayer);
        }
    }

    getLayers() {
        return this.map.getLayers().array_;
    }

    getWMSLayers() {
        let wmsLayers = [];
        for (let layer of this.getLayers()) {
            if (layer.getSource() instanceof ImageWMS || layer.getSource() instanceof TileWMS){
                wmsLayers.push(layer);
            }
        }

        return wmsLayers;
    }

    // Find the layer and update it
    updateWMSLayer = (layerName, filterKeys) => {
        var this_ = this;
        var now = Date.now();

        this_.map.getLayers().forEach(function (olLayer) {
            if (olLayer.get('name') === layerName) {
                
                if (Object.keys(filterKeys).length === 0) {
                    olLayer.setVisible(false);
                }
                else {
                    var source = olLayer.getSource();
                    var params = source.getParams();

                    //Add filters to the params
                    for (const key in filterKeys) {
                        let value = filterKeys[key];
                        params[key] = value;
                    }

                    params.t = now;
                    source.updateParams(params);

                    if (olLayer.getSource() instanceof ImageWMS) {
                        source.setImageLoadFunction(source.getImageLoadFunction());
                    }
                    else if (olLayer.getSource() instanceof TileWMS) {
                        source.setTileLoadFunction(source.getTileLoadFunction());
                    }

                }
            }
        });
    }

    setBaseLayer = (baseLayerName) => {
        // BaseLayerType is equal "Road" or "Satellite" or "Outdoor"
        var this_ = this;
        this_.map.getLayers().forEach(function (olLayer) {
            var isBase = olLayer.get('type');
            if (isBase === 'base') {
                var title = olLayer.get('title');
                if (title === 'BaseLayer_' + baseLayerName) {
                    olLayer.setVisible(true);
                    this_.currentBaseLayer = olLayer;
                } else {
                    olLayer.setVisible(false);
                };
            };
        });
    };

    addBaseLayer = (baseLayerName, baseLayerUrl) => {
        var visible = false;

        var rasterLayer = new TileLayer({
            title: 'BaseLayer_' + baseLayerName,
            type: 'base',
            icon: 'globe',
            tooltip: 'external',
            visible: visible,
            source: new XYZ({
                url: baseLayerUrl
            })
        });

        this.map.addLayer(rasterLayer);
    }

    getMap = () => {
        return this.map;
    }

    updateMapSize = () => {
        this.map.updateSize();
    }

    checkTokenExpiry = () =>{
        if (this.accessTokenExpiryTime != null) {
            
            //Allow a bit of time before token expiry so things are smooth
            let refreshTimeSecs = 10;

            var seconds = (this.accessTokenExpiryTime.getTime() - new Date().getTime() ) / 1000;
            
            if (seconds <= refreshTimeSecs && !this.tokenRefreshRequested ) {

                //Token expiring soon or expired so we need to request a new one
                this.tokenRefreshRequested = true;              

                if (this.callBackHandler) {
                    this.callBackHandler.onTokenRefreshRequest();
                }
            }
        }
    }

    orthographicTileLoader = (image, src) => {
        this.checkTokenExpiry();
        let client = new XMLHttpRequest();
        client.open('GET', src);
        client.withCredentials = false;

        client.setRequestHeader("Authorization", "Bearer " + this.accessToken);

        client.onload = () => {
            let data = 'data: image/png;base64,' + client.responseText;
            image.getImage().setAttribute('src', data);
        }
        client.send();
    }
    
    customWMSLoader = (image, src) => {
        this.checkTokenExpiry();
        let client = new XMLHttpRequest();
        client.open('GET', src);
        client.withCredentials = false;

        client.setRequestHeader("Authorization", "Bearer " + this.accessToken);

        client.onload = () => {
            let data = 'data: image/png;base64,' + client.responseText;
            image.getImage().setAttribute('src', data);
        }
        //im leaving this here for nitish until we have the scalebar 01/12/2020 - Mike
        //console.log("[DEBUG] map zoom: " + this.map.getView().getZoom(), "map scale: " + this.getScaleFromResolution(this.map.getView().getResolution(), 'm'));
        client.send();
    }

    updateAccessToken = (accessToken,expiryTime) => {
        this.accessToken = accessToken;
        this.accessTokenExpiryTime = expiryTime;
        this.tokenRefreshRequested = false;
    }

    zoomToExtent = (minLng, minLat, maxLng, maxLat) => {
        var boundingExtent = transformExtent([minLng, minLat, maxLng, maxLat], this.dataProjection, this.mapProjection);
        this.map.getView().fit(boundingExtent, this.map.getSize());
    }

    panTo = (lon, lat) => {
        this.map.getView().animate({
            center: fromLonLat([lon, lat]),
            duration: this.PAN_ANIMATE_SPEED

        });
    }

    zoomTo = (zoomLevel) => {
        this.map.getView().animate({
            zoom: zoomLevel,

            duration: this.ZOOM_ANIMATE_SPEED
        });
    }

    enableDragBox = (state) =>
    {
        let this_ = this; // scoped to this function
        this_.dragBox.setActive(state);
    }

    removeLayerIfExists = (layerName) => {
        let this_ = this; // scoped to this function
        let theLayer = null;

        //check if Layer exists already
        this_.map.getLayers().forEach(function (layer) {
                if (layer.get('name') === layerName) {
                    theLayer = layer;                    
                }
            });
        
        //remove layer if exists
        if (theLayer != null) {
            this_.map.removeLayer(theLayer);
        } 
    }

    refreshSelectionIconLayer = (layerName, featuresWithLatLon) => {
        let this_ = this; // scoped to this function
        let selectionIconLayer = null;
        let features = [];
        //Check if the layer exists
        this_.map.getLayers().forEach(function (layer) {
            if (layer.get('name') === layerName) {
                selectionIconLayer = layer;
            }
        });

         //Create SelectionIcon vector layer if not present
        if (selectionIconLayer == null) {

            //Create SelectionIcon vector layer if not present
            var source = new VectorSource({
                features: []
            });

            var style = new Style({
                image: new Icon({
                    size: [86, 86],
                    anchor: [0.5,1.35],
                    opacity: 1,
                    scale: 0.4,
                    src: SelectionIcon
                }),
            });

            selectionIconLayer = new VectorLayer({
                source: source,
                name: 'selectionIconLayer',
                style: style
            });

            this_.map.addLayer(selectionIconLayer);
            selectionIconLayer.setZIndex(1000);
        }

        let selectionSource = selectionIconLayer.getSource();
        let sourceFeatures = selectionSource.getFeatures();

        // Remove features that have been unselected
        for (let sourceFeature of sourceFeatures) {
             let featureExists = false;

             for (var i = 0; i < featuresWithLatLon.length; i++) {
                 if (sourceFeature.getId() ===  featuresWithLatLon[i].id)
                 {
                     featureExists = true;
                     break;
                 }
             }

            if (!featureExists)
                selectionSource.removeFeature(sourceFeature);
        }

        //Create selection layer features
        for (var i = 0; i < featuresWithLatLon.length; i++) {

            let featureExists = false;

            for (let sourceFeature of sourceFeatures) {
                if (sourceFeature.getId() === featuresWithLatLon[i].id) {
                    featureExists = true;
                    break;
                }
            }

            if ( featureExists )
                continue;

            if (featuresWithLatLon[i].lon != null || featuresWithLatLon[i].lat != null) {
                var geom = new Point(fromLonLat([featuresWithLatLon[i].lon, featuresWithLatLon[i].lat]));
                var feature = new Feature(geom);

                feature.setId(featuresWithLatLon[i].id);

                features.push(feature);
            }
        }

        //Add the new data        
        selectionSource.addFeatures(features);
     
        // Re-render the map
        this_.map.render();
    }
  
    addHighlightFeature = (lon, lat) => {
        let this_ = this; // scoped to this function
        let source = null;
        const highlightLayerName = 'highlightLayer'
        this_.removeLayerIfExists(highlightLayerName);

        var radius = 20;
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        context.clearRect(0, 0, radius, radius);
        var gradient = (function () {
            var grad = context.createRadialGradient(radius,radius,1,radius,radius,radius);
            grad.addColorStop(2 / 3,'rgba(255,97,7, 1)');
            grad.addColorStop(1, 'rgba(255,97,7, 0)');
            return grad;
        })();

        source = new VectorSource({
                    wrapX: false,
                });

        var style = new Style({
            image: new CircleStyle({
                radius: radius,
                displacement: [-0.5,0],
                fill: new Fill({
                    color: gradient
                })
            }),
        });
    
        let vector = new VectorLayer({
            source: source,
            name: highlightLayerName,
            style: style,
            minZoom: 16,
            maxZoom: 23,
            zIndex: 901
        });
        
        this_.map.addLayer(vector);
        source.clear();

        var geom = new Point(fromLonLat([lon, lat]));
        var feature = new Feature(geom);

        source.addFeature(feature);

        // Re-render the map
        this_.map.render();
    }

    addImageHighlightFeature = (lon, lat, icon) => {
        let this_ = this; // scoped to this function
        let source = null;
        const imageHighlightLayerName = 'imageHighlightLayer'
        this_.removeLayerIfExists(imageHighlightLayerName);
        
        source = new VectorSource({
                    wrapX: false,
                });

        var style = new Style({
            image: new Icon({
                size: [24, 24],
                opacity: 1,
                scale: 1,
                src: icon
            }),
        });
    
        let vector = new VectorLayer({
            source: source,
            name: imageHighlightLayerName,
            style: style,
            minZoom: 16,
            maxZoom: 23,
            zIndex: 902
        });
        
        this_.map.addLayer(vector);
        source.clear();

        var geom = new Point(fromLonLat([lon, lat]));
        var feature = new Feature(geom);

        source.addFeature(feature);

        // Re-render the map
        this_.map.render();
    }

    extractRgbaValues = (rbgastring) => {
        let colVals = rbgastring.split("rgba")[1]
        .replace('(',"")
        .replace(')',"");
        let rgbarr = colVals.split(','); 
        if (rgbarr.__proto__ !== [].__proto__ ||  rgbarr.length < 4)
            return null;
        for (let i = 0; i < rgbarr.length; i++)
            rgbarr[i] = parseFloat(rgbarr[i].trim());
        return rgbarr;
    }
    
    addLinePulseFeature = ( rgb, wkt) => {
        let this_ = this; // scoped to this function
        const pulseLayerName = 'pulseLayer';
        let source = null;
        let theLayer = null;

        let rgbarr = this.extractRgbaValues(rgb);
        if (!rgbarr) return;

       var style =  new Style({
                stroke: new Stroke({
                    color: rgb,
                    width: 8
                }),
                fill: new Fill({
                    color: rgb
                })
            });

        this_.map.getLayers().forEach(function (layer) {
            if (layer.get('name') === pulseLayerName) {
                theLayer = layer;
            }
        });

        if (theLayer != null) {
            source = theLayer.getSource();
            source.clear();            
        }
        else {
            source = new VectorSource({
                wrapX: false,
            });

            let vector = new VectorLayer({
                source: source,
                name: pulseLayerName
            });

            vector.setStyle(style);

            source.on('addfeature', function (e) {
                this_.flashLineFeature(e.feature, rgbarr);
            });
        }
        
        // Add the feature geom
        var format = new WKT();
        var feature = format.readFeature(wkt);
        feature.getGeometry().transform(this_.dataProjection, this_.mapProjection);

        source.addFeature(feature);

        // Re-render the map
        this_.map.render();
    }

    //Example: 'rgba(247, 181, 0, 0.5)'
    addPulseFeature = (lon, lat, rgb) => {
        let this_ = this; // scoped to this function
        const pulseRadius = 13;
        const width = 1;
        const point = 10;
        const pulseLayerName = 'pulseLayer';
        let source = null;
        let theLayer = null;

        let rgbarr = this.extractRgbaValues(rgb);
        if (!rgbarr) return;
       
        var style = new Style({
            image: new CircleStyle({
                radius: pulseRadius,
                Point: point,
                stroke: new Stroke({
                    color: rgb,
                    width: width,
                }),
            }),
        });

        this_.map.getLayers().forEach(function (layer) {
            if (layer.get('name') === pulseLayerName) {
                theLayer = layer;
            }
        });

        if (theLayer != null) {
            source = theLayer.getSource();
            source.clear();            
        }
        else {
            source = new VectorSource({
                wrapX: false,
            });

            let vector = new VectorLayer({
                source: source,
                name: pulseLayerName
            });

            vector.setStyle(style);

            source.on('addfeature', function (e) {
                this_.flash(e.feature, rgbarr);
            });
        }


        // Add the feature geom
        var geom = new Point(fromLonLat([lon, lat]));
        var feature = new Feature(geom);

        source.addFeature(feature);

        // Re-render the map
        this_.map.render();
    }

 flashLineFeature = (feature, rgbarr) => {
        const duration = this.pulseTimeout;

        var start = new Date().getTime();
        var listenerKey = this.currentBaseLayer.on('postrender', (event) => {
            var vectorContext = getVectorContext(event);
            var frameState = event.frameState;
            var flashGeom = feature.getGeometry().clone();
            var elapsed = frameState.time - start;
            var elapsedRatio = elapsed / duration;

            var opacity = easeOut(1 - elapsedRatio);
    
           var style =  new Style({
                stroke: new Stroke({
                    color: 'rgba(' + rgbarr[0] + ', '+ rgbarr[1] + ', ' + rgbarr[2] + ', ' + opacity + ')',
                    width: 8
                }),
                fill: new Fill({
                    color: 'rgba(' + rgbarr[0] + ', '+ rgbarr[1] + ', ' + rgbarr[2] + ', ' + opacity + ')',
                })
            });

            vectorContext.setStyle(style);
            vectorContext.drawGeometry(flashGeom);
            if (elapsed > duration) {
                unByKey(listenerKey);
                return;
            }

            // Tell OpenLayers to continue postrender animation
            this.map.render();
        });
    }

    flash = (feature, rgbarr) => {
        const duration = this.pulseTimeout;

        var start = new Date().getTime();
        var listenerKey = this.currentBaseLayer.on('postrender', (event) => {
            var vectorContext = getVectorContext(event);
            var frameState = event.frameState;
            var flashGeom = feature.getGeometry().clone();
            var elapsed = frameState.time - start;
            var elapsedRatio = elapsed / duration;
            // radius will be 5 at start and 30 at end.
            var radius = easeOut(elapsedRatio) * 10;
            var opacity = easeOut(1 - elapsedRatio);

            var style = new Style({
                image: new CircleStyle({
                    radius: radius,
                    displacement: [-0.5,0],
                    stroke: new Stroke({
                        color: 'rgba(' + rgbarr[0] + ', '+ rgbarr[1] + ', ' + rgbarr[2] + ', ' + opacity + ')',
                        width: 20,
                    }),
                }),
            });

            vectorContext.setStyle(style);
            vectorContext.drawGeometry(flashGeom);
            if (elapsed > duration) {
                unByKey(listenerKey);
                return;
            }

            // Tell OpenLayers to continue postrender animation
            this.map.render();
        });


    }

    getWktForMapExtent = () => {
        let this_ = this; 

        var extent = this.map.getView().calculateExtent(this.map.getSize())
        var format = new WKT();

        return format.writeGeometry(fromExtent(extent), {
            dataProjection: this_.dataProjection,
            featureProjection: this_.mapProjection
        });
    }

    addSegmentObjectLayer = (layerName) => {

        if ( this.doesLayerExist(layerName))
            return;

        // Layer features are not visible but can be detected on mouseover
        let fillColor = 'rgba(0,0,0, 0.0)';

        //set this to see features for debugging
        //fillColor = 'rgba(255,0,0, 1.0)';

        let fill = new Fill({color: fillColor});
        let stroke = new Stroke({color: fillColor, width: 1});
        let style = new Style({
            image: new RegularShape({
              fill: fill,
              stroke: stroke,
              points: 4,
              radius: 14,
              angle: Math.PI / 4,
            }),
          });

        let vector = new VectorLayer({
            source: new VectorSource({
                wrapX: false,
            }),
            name: layerName,
            style: style,
            minZoom: 16,
            maxZoom: 23,
        });

        this.map.addLayer(vector);
    }

    getSegmentHighlightLayerStyle = (opacity) => {

        return new Style({
            image: new CircleStyle({
                fill: new Fill({
                    color: 'rgba(174, 213, 129, ' + (opacity * 0.6) + ')',
                }),
                radius: 19,
                stroke: new Stroke({
                    color: 'rgba(174, 213, 129, ' + opacity + ')',
                    width: 1,
                }),
            }),
        });
    }

    addSegmentHighlightLayer = (layerName) => {
        if (this.doesLayerExist(layerName))
            return;

        let vector = new VectorLayer({
            source: new VectorSource({
                wrapX: false,
            }),
            name: layerName,
            style: this.getSegmentHighlightLayerStyle(1),
            minZoom: 16,
            maxZoom: 23,
        });

        this.map.addLayer(vector);
    }

    addFeaturesToLayer = (objects,layerName) => {

        // objects must have props lat, lon and id. If it has a 'properties' prop that will be added as feature properties

        let layer = this.getLayer(layerName);
        if( layer == null)
            return;
        
        let source = layer.getSource();

        let features = [];

        let sourceFeatures = source.getFeatures();

        for (let object of objects) {
            let featureExists = false;

            // If the feature already exists with that id dont add again
            for (let sourceFeature of sourceFeatures) {
                if (sourceFeature.getId() === object.id) {
                    featureExists = true;
                    break;
                }
            }

            if (featureExists)
                continue;

            let feature = new Feature({
              geometry: new Point(fromLonLat([object.lon, object.lat])),
            });

            feature.setId(object.id);
            if (object.properties != null)
                feature.setProperties(object.properties);

            features.push(feature);
        }
        
        if (features.length > 0) {
            source.addFeatures(features);
        }
    }

    getLayer = (layerName) => {

        let theLayer = null;

        this.map.getLayers().forEach(function (layer) {
            if (layer.get('name') === layerName) {
                theLayer = layer;
            }
        });

        return theLayer;
    }


    getLayerFeatures = (layerName) => {

        let theLayer = this.getLayer(layerName);

        return theLayer != null ? theLayer.getSource().getFeatures() : [];
    }
    
    clearLayer = (layerName) => {

        let theLayer = this.getLayer(layerName);
        if (theLayer != null) 
            theLayer.getSource().clear();
    } 

    transformFromMapProjectionToDataProjection = (cord) => {
        // Transform to lat lon
        return transform([cord[0], cord[1]], this.mapProjection, this.dataProjection);
    }

    isLayerVisibleAtCurrentZoomLevel = (layerName) => {
        let theLayer = this.getLayer(layerName);

        return theLayer != null ? theLayer.getMinZoom() <= this.map.getView().getZoom() : false;
    }

}
