import * as React from 'react';

import { ComponentPropertySyncConfig } from '../ComponentPropertySyncConfig'

import {getPropertiesThatHaveChanged, updatePropertiesFromUpdates, getPropertyValuesFromProps} from '../StateSyncCommon';

import {IPropertyValue} from '../../../models/IPropertyValue';
import { IDefect } from '../../../models/IDefect';
import { IImage } from '../../../models/IImage';
import { IImageDefectCount } from '../../../models/IImageDefectCount';
import { IThreeDViewerConfig } from '../../../models/IThreeDViewerConfig';
import { ICoordinate } from '../../../models/ICoordinate';

interface IParentWindowPostMessageStateSyncProps {
  hostComponentType:string;
  defects: IDefect[];
  onStateIntialised: Function;
  onStateChanged: Function;
  windowTitle:string;
  isDefectModeEnabled:boolean;
  selectedDziImage: IImage | null;
  updatedImageIds: number[] | null;
  imageDefectCount : IImageDefectCount[];
  threeDViewerConfig: IThreeDViewerConfig | null;
  flyToLocation: ICoordinate | null;
};

interface IParentWindowPostMessageStateSyncState {
}

export class ParentWindowPostMessageStateSync extends React.Component<IParentWindowPostMessageStateSyncProps, IParentWindowPostMessageStateSyncState> {
   windowId:string = '';
   propertyValues: IPropertyValue[] = [];
   initalised: boolean = false;
   propertiesToSync: string[] = [];

   constructor(props: IParentWindowPostMessageStateSyncProps) {
      super(props);
      
      this.windowId = 'Popup_' + this.props.hostComponentType + '_' + new Date().getTime().toString(); 

      // Handle messages from the parent window 
      let this_ = this;
      window.addEventListener("message", function (event) {
        try {
          if(event.origin.split(":", 2).join(":") === window.location.origin.split(":", 2).join(":")){
            this_.processMessage(event);
          }
        } catch (e) {
          // We dont care about this error as non vaa messages can cause exceptions
        }
      }
      , false);   
     
     // Handle window closed
      window.addEventListener("unload", function (event) {
        this_.sendMessageToParentWindow('VAA_POPUP_CLOSED',{windowId: this_.windowId});
      }
      , false);   
     
     let config = ComponentPropertySyncConfig.find(c=>c.componentType === this.props.hostComponentType);
     if (config == null) {
       console.log('ERROR: Invalid popup container config');
       return;
     }
     
     this.propertiesToSync = config.propertiesToSync;

     this.sendMessageToParentWindow('VAA_POPUP_INITIALISED',{componentType:this.props.hostComponentType, windowId: this.windowId});
  }

  processMessage(event:any) {
    let message = event.data;
    let data = message.package ? JSON.parse(message.package) : null; 

    if ( message.action == null || message.action.indexOf('VAA') !== 0 )
      return;

    try {
      //console.log('State Sync received message ' + message.action + ' from window ' + message.sender);

      switch (message.action) {
        case "VAA_PARENT_WINDOW_STATE_UPDATE":
          this.handleStateUpdate(data as IPropertyValue[]);

          if (!this.initalised) {
            // This is the first state update so signal to the host container that it can now render
            this.initalised = true;
            this.props.onStateIntialised();
          }
          break;

      }
    } catch (e) {
      console.log('ERROR "' + e + '"  in handling message: ' + message.action + ' package: ' + message.package);
    }
  }

  componentDidUpdate(prevProps:IParentWindowPostMessageStateSyncProps) {
    
    //Sync updated props back to the parent window.  Only configured properties are sent
    window.document.title = this.props.windowTitle;
    let newPropertyValues = getPropertyValuesFromProps(this.props).filter(property => this.propertiesToSync.includes(property.name));

    // Work out what properties have changed
    let updatedProperties = getPropertiesThatHaveChanged(this.propertyValues, newPropertyValues);

    if (updatedProperties.length > 0) {

      // Update the local properties with new updates
      updatePropertiesFromUpdates(this.propertyValues, updatedProperties);

      // Send to parent
      this.sendMessageToParentWindow('VAA_POPUP_WINDOW_STATE_UPDATE', updatedProperties);
    }
  }

  setPropertyValue(name: string, val: any){ 
    let propertyValue = this.propertyValues.find(p=>p.name == name);
    if (propertyValue != null) {
      propertyValue.value = val;
    }
    else {
      this.propertyValues.push({name:name,value:val});
    }
  }

  // Take updates from the parent and emit as a state update object for the host container to apply
  handleStateUpdate(propertyUpdates: IPropertyValue[]) {

    let stateUpdate: any = {};

    for (let propertyUpdate of propertyUpdates) {
      
      if ( !this.propertiesToSync.includes(propertyUpdate.name))
        continue;

      let currentProperty = this.propertyValues.find(p=>p.name == propertyUpdate.name);

      // Only emit an update if the value has not been previously updated or is different
      let updateState = currentProperty == null || JSON.stringify(propertyUpdate.value) != JSON.stringify(currentProperty.value);
      
      this.setPropertyValue(propertyUpdate.name,propertyUpdate.value);

      if ( updateState )
      {
        stateUpdate[propertyUpdate.name] = propertyUpdate.value;
      }
    }

    if(Object.keys(stateUpdate).length > 0){
       // Emit update event so the state can be set in the parent containers state
       this.props.onStateChanged(stateUpdate);
    }
  }

  sendMessageToParentWindow(action:any, data:any) {

    let msg = {
      action: action,
      sender: this.windowId,
      package: JSON.stringify(data)
    };

    window.opener.postMessage(msg,"*");
  }


  render() {
    return null; //no ui
  }
}