import React from 'react';
import OpenLayersMap from './OpenLayersMap'
import styles from './Map.module.css'

import { ICoordinate } from '../../models/ICoordinate';
import { IMapClick } from '../../models/IMapClick';
import { IMapDragBox } from '../../models/IMapDragBox';
import { IImageSearchCreateResponse } from '../../models/IImageSearchCreateResponse';
import { IAssetSearchResponse } from '../../models/IAssetSearchResponse';
import { IImage } from '../../models/IImage';
import { ISegment } from '../../models/ISegment';
import { IAsset } from '../../models/IAsset';
import { createNonNullExpression } from 'typescript';
import { ILinkingResult } from '../../models/ILinkingResult';

import { postWithAuthorisationHeader, fetchWithAuthorisationHeader, getAuthorizationData, getTokenExpiryTime, refreshToken } from "../../services/AuthenticationService";
import { IMapLayer, GeometryType } from '../../models/IMapLayer';
import { IShapefileData } from '../../models/IShapefileData';
import { deepClone } from '../Common/Common';
import checkCircle from "../../images/map/check_circle_24px.png";
import cancelCircle from "../../images/map/cancel_24px.png";
import inactiveAssetCircle from "../../images/map/check_circle_inactive.png"; 

const IMAGE_LAYERS = ['ImageSearchResult', 'Segment']
const defaultZoomLevel = 18;
var   newZoomLevel =18; 
const clusterZoomLevel = 16;
const NO_FILE_ID:number = -255;
const DEFAULT_ORTHO_LAYER_ZINDEX = 0;

interface IMapProps {
    mapOptions: any;
    baseMapLayers: any[];
    currentBaseLayer: string;
    imageSearch: IImageSearchCreateResponse | null;
    imageSearchZoomExtent: boolean;
    assetSearch: IAssetSearchResponse | null;
    selectedImageRow: IImage | null; 
    selectedAssetRow: IAsset | null;
    isSelectModeEnabled: boolean;
    selectedDziImage: IImage | null;
    onMapSingleClick: Function;
    onMapSingleClickObjects: Function;
    onDragBoxEnd: Function;
    onMapMoveEnd: Function;
    onMapMoveStart: Function;
    mapDataLayers: IMapLayer[][] | [][];
    selectedImageIds: number[];
    isLinkModeEnabled: boolean;    
    linkingResult: ILinkingResult | null;
    refreshMapLayersFlag: number;
    onLinkPole: Function;
    urlLatLong : ICoordinate | null;
};


export class Map extends React.Component<IMapProps> {
    openLayersMap: OpenLayersMap | null = null;
    pulseInterval:any = null;
    pulseTimeout:any = null;
    segmentHighlightLayerName: string = 'SegmentHighlightLayer';
    segmentObjectLayerName: string = 'SegmentObjectLayer';
    constructor(props: IMapProps) {
        super(props);

    }

    state = {
        isURLCoordsApplied: false
    }

  // Called when the dom has been rendered
  componentDidMount() {
      if (!this.props.mapOptions) {
          return;
      }
      try {
          this.openLayersMap = new OpenLayersMap(this.props.mapOptions, this.props.baseMapLayers, 'map', this);          
          this.openLayersMap.updateMapSize();
          this.openLayersMap.setBaseLayer(this.props.currentBaseLayer);  
      }
      catch (e) {
          console.log("ERROR Could not instantiate Map: " + e);
          return;
      }
      //load mapserver layers
      for (var i = 0; i < this.props.mapDataLayers.length; ++i) {
          for (var j = 0; j < this.props.mapDataLayers[i].length; ++j) {
              let mapDataLayer = this.props.mapDataLayers[i][j];
              if (mapDataLayer.group === 'OrthoLayer') {
                  try {
                      this.openLayersMap.createOrthographicLayer(
                          mapDataLayer.label,
                          mapDataLayer.url,
                          mapDataLayer.isActive,
                          mapDataLayer.min,
                          mapDataLayer.max,
                          DEFAULT_ORTHO_LAYER_ZINDEX
                      );

                      this.openLayersMap.showLayer(mapDataLayer.label, mapDataLayer.isActive);
                  } catch (e) {
                      console.log('ERROR,Could not add orthographic Layer ' + mapDataLayer.label + ': ' + e);
                      continue;
                  }
              }
              else {
                  try {
                      this.openLayersMap.createWMSLayer(
                          mapDataLayer.group,
                          null,
                          null,
                          mapDataLayer.tiled,
                          mapDataLayer.zOrder,
                          mapDataLayer.min,
                          mapDataLayer.max);

                      this.openLayersMap.showLayer(mapDataLayer.group, mapDataLayer.isActive);
                  } catch (e) {
                      console.log("ERROR,Could not add mapserver Layer " + mapDataLayer.group + ": " + e);
                      return;
                  }
              }
          }
      }
    }

    //our custom method of flying and highlighting a point
    focusObjectOnMap(olm:OpenLayersMap, lon:number, lat:number,rgbaColour:string,dataType:string, wkt:string,zoomLevel:number) {
        if (olm === null || lat === undefined || lon === undefined) return;

        olm.panTo(lon, lat);
        olm.zoomTo(zoomLevel);
          
        clearInterval(this.pulseInterval);
        this.pulseInterval = null;

        clearTimeout(this.pulseTimeout);
            
        // Draw vector with animation
        this.pulseInterval = setInterval(() => {
            if (olm == null) return;

            if (dataType === "Line" && wkt != null)
              olm.addLinePulseFeature( rgbaColour, wkt);
            else
              olm.addPulseFeature(lon, lat, rgbaColour);
        }, 1000);
        
        this.pulseTimeout = setTimeout(() => {
            if (this.pulseInterval != null) {
                clearInterval(this.pulseInterval);
                this.pulseInterval = null;
            }
            
            }, 3000);
    }

    //sets z index in place, returns reference to array
    sortMapLayersZIndexFromGeometryType(a:IMapLayer[][], startingZIndex:number):IMapLayer[][] {
        let geomTypeIdx:number = 0;
        for (let i = 0; i < a.length && geomTypeIdx < Object.keys(GeometryType).length/2; i++) {    // length/2 due to TS enum reverse mapping
            for (let j = 0; j < a[i].length; j++) {
                if (a[i][j].geometryType === geomTypeIdx) {
                    a[i][j].zOrder = startingZIndex++;
                }
                if (i == a.length -1 && j == a[i].length -1) {
                    i = 0; 
                    ++geomTypeIdx;
                }
            }
        }
        return a;
    }

    // Called when properties change
    componentDidUpdate(prevProps: IMapProps) {
        if (this.openLayersMap == null)
            return;

        let imageSearchLayers = ['ImageSearchResult','ImageSearchResultClustering', 'ImageBearingLinked', 'ImageBearingUnlinked', 'AssetImageLink'];

        //first check if any new map layers need to be loaded
        if (this.props.mapDataLayers.length !== prevProps.mapDataLayers.length) {
          let prevLen = prevProps.mapDataLayers.length;
          //copy new layers
          let sortedNewMapLayers: IMapLayer[][] = deepClone<IMapLayer[][]>(this.props.mapDataLayers.slice(prevLen));

          var maxZIndex: number = 0;
          for (let i = 0; i < prevProps.mapDataLayers.length; ++i) {
            for (let j = 0; j < prevProps.mapDataLayers[i].length; ++j) {
              if (prevProps.mapDataLayers[i][j].zOrder > maxZIndex && prevProps.mapDataLayers[i][j].zOrder < 900) {
                maxZIndex = prevProps.mapDataLayers[i][j].zOrder;
              }
            }
          }

          this.sortMapLayersZIndexFromGeometryType(sortedNewMapLayers, maxZIndex);

          for (let i = 0; i < sortedNewMapLayers.length; ++i) {
            for (let j = 0; j < sortedNewMapLayers[i].length; ++j) {
              let sortedNewMapLayer = sortedNewMapLayers[i][j];

              if (!sortedNewMapLayer) {
                throw 'Layer is null';
              }

              if (sortedNewMapLayer.group === 'OrthoLayer') {
                try {
                  this.openLayersMap.createOrthographicLayer(
                    sortedNewMapLayer.label,
                    sortedNewMapLayer.url,
                    sortedNewMapLayer.isActive,
                    sortedNewMapLayer.min,
                    sortedNewMapLayer.max,
                    DEFAULT_ORTHO_LAYER_ZINDEX
                  );
                } catch (e) {
                  console.log('ERROR,Could not add orthographic Layer ' + sortedNewMapLayer.label + ': ' + e);
                  continue;
                }
              } else {
                try {
                  this.openLayersMap.createWMSLayer(
                    sortedNewMapLayer.group,
                    null,
                    null,
                    sortedNewMapLayer.tiled,
                    sortedNewMapLayer.zOrder,
                    sortedNewMapLayer.min,
                    sortedNewMapLayer.max
                  );
                } catch (e) {
                  console.log('ERROR,Could not add mapserver Layer ' + sortedNewMapLayer.group + ': ' + e);
                  continue;
                }
              }
            }
          }
        }
        
        //next update any changed properties of ap layers
        if (this.props.mapDataLayers != prevProps.mapDataLayers) {
            
            let activeShapefileIds = [];
            for (let i = 0; i < prevProps.mapDataLayers.length; i++) {
                for (let j = 0; j < prevProps.mapDataLayers[i].length; j++) {

                    if (this.props.mapDataLayers[i][j].group == "Shapefiles" && this.props.mapDataLayers[i][j].isActive){
                        activeShapefileIds.push(this.props.mapDataLayers[i][j].fileId);
                    }

                    if (this.props.mapDataLayers[i][j].isActive != prevProps.mapDataLayers[i][j].isActive && this.props.mapDataLayers[i][j].group != "Shapefiles") {
                        if(this.props.mapDataLayers[i][j].group === "OrthoLayer"){
                            this.openLayersMap.showLayer(this.props.mapDataLayers[i][j].label, this.props.mapDataLayers[i][j].isActive);
                        }
                        else{
                            this.openLayersMap.showLayer(this.props.mapDataLayers[i][j].group, this.props.mapDataLayers[i][j].isActive);
                        }
                    }
                }
            }
            
            this.openLayersMap.showLayer("Shapefiles", activeShapefileIds.length > 0); 
            this.openLayersMap.updateWMSLayer("Shapefiles", {fileIds: activeShapefileIds});


        }

        if (this.props.currentBaseLayer != prevProps.currentBaseLayer) {
            this.openLayersMap.setBaseLayer(this.props.currentBaseLayer);
        }
        

        if (this.props.imageSearch != prevProps.imageSearch) {  

            // An image search has been created or cleared
            //let layerName = 'ImageSearchResult';
            //let zIndex = 1;
            // Pass a dummy guid if search is null so that the map layers gets redrawn with no results
            let filter = this.props.imageSearch != null ? { searchId: this.props.imageSearch.searchId } : {searchId: '00000000-0000-0000-0000-a00000000000'};
           
            for (var i = 0; i < imageSearchLayers.length; ++i) {
                this.openLayersMap.updateWMSLayer(imageSearchLayers[i], filter);
            }            

            
            // Get the extent and zoom to it
            if (this.props.imageSearch != null && this.props.imageSearch.totalImagesCount > 0 && this.props.imageSearchZoomExtent) {
                this.openLayersMap.zoomToExtent(
                    this.props.imageSearch.extent.st_xmin,
                    this.props.imageSearch.extent.st_ymin,
                    this.props.imageSearch.extent.st_xmax,
                    this.props.imageSearch.extent.st_ymax
                );
                // setting zoom level to default for new search 
                newZoomLevel = defaultZoomLevel;
               
            }
        }

        if (this.props.assetSearch != prevProps.assetSearch) {  
            // Get the extent and zoom to it
            if (this.props.assetSearch != null && this.props.assetSearch.segments.length > 0) {
                this.openLayersMap.zoomToExtent(
                    this.props.assetSearch.searchResultExtent.st_xmin,
                    this.props.assetSearch.searchResultExtent.st_ymin,
                    this.props.assetSearch.searchResultExtent.st_xmax,
                    this.props.assetSearch.searchResultExtent.st_ymax
                );
                // setting zoom level to default for new search 
                newZoomLevel = defaultZoomLevel;
            }
        }

        var authData = getAuthorizationData();
        try {
            this.openLayersMap.updateAccessToken(authData.token, getTokenExpiryTime());
        }
        catch (e) {
            console.log("Failed to update access token");
        }

        if (this.props.urlLatLong !== null && this.props.urlLatLong.longitude && this.props.urlLatLong.latitude && !this.state.isURLCoordsApplied) {
          this.openLayersMap.panTo(this.props.urlLatLong.longitude, this.props.urlLatLong.latitude);
          this.openLayersMap.zoomTo(19);
          this.setState({ isURLCoordsApplied: true });
        }

        //asset row fly to
        if (this.props.selectedAssetRow != null && this.props.selectedAssetRow != prevProps.selectedAssetRow) {
            var highlightColour = 'rgba(149, 197, 231, 0.5)';

            if ( this.props.selectedAssetRow.displayColor != null &&  this.props.selectedAssetRow.displayColor.length > 0 )
                highlightColour = this.getRGBAFromHexColour(this.props.selectedAssetRow.displayColor,0.5);

            this.focusObjectOnMap(this.openLayersMap, this.props.selectedAssetRow.lon, this.props.selectedAssetRow.lat, highlightColour, this.props.selectedAssetRow.dataType, this.props.selectedAssetRow.wkt, newZoomLevel);
        }

        //image row fly to
        if (this.props.selectedImageRow != null && this.props.selectedImageRow != prevProps.selectedImageRow) {
            this.focusObjectOnMap(this.openLayersMap, this.props.selectedImageRow.lon, this.props.selectedImageRow.lat, 'rgba(247, 181, 0, 0.5)', '' ,'',newZoomLevel);
        } 

        // Highlight selected DZI image on map. 
        if (this.props.selectedDziImage != null && this.props.selectedDziImage != prevProps.selectedDziImage){
            this.openLayersMap.addHighlightFeature(this.props.selectedDziImage.lon, this.props.selectedDziImage.lat);
            this.openLayersMap.addImageHighlightFeature(this.props.selectedDziImage.lon, this.props.selectedDziImage.lat, this.getImageStatusIcon(this.props.selectedDziImage.poleId, this.props.selectedDziImage.active ) );
        }
        if (this.props.selectedDziImage == null && prevProps.selectedDziImage != null){
            this.openLayersMap.removeLayerIfExists('highlightLayer');
            this.openLayersMap.removeLayerIfExists('imageHighlightLayer');
        }
        
        if (this.props.selectedImageIds != prevProps.selectedImageIds) {
            this.highlightSelectedImagesInCurrentMapExtent();
        }

        if (this.props.isSelectModeEnabled != prevProps.isSelectModeEnabled) {
            this.openLayersMap.enableDragBox(this.props.isSelectModeEnabled);

            if (!this.props.isSelectModeEnabled) {
                this.openLayersMap.removeLayerIfExists('selectionIconLayer');
            }
        }

        if (this.props.imageSearch != prevProps.imageSearch) {
            this.openLayersMap.removeLayerIfExists('selectionIconLayer');
        }

        if (this.props.isLinkModeEnabled != prevProps.isLinkModeEnabled) {

            if (this.props.isLinkModeEnabled) {
                this.openLayersMap.removeLayerIfExists(this.segmentHighlightLayerName);
                this.openLayersMap.removeLayerIfExists(this.segmentObjectLayerName);                

                this.openLayersMap.addSegmentObjectLayer(this.segmentObjectLayerName);

                if (this.props.selectedImageIds.length > 0) {
                    this.openLayersMap.addSegmentHighlightLayer(this.segmentHighlightLayerName);
                }

                this.getSegmentObjectLayerFeaturesForCurrentMapArea();
            }
            else {
                this.openLayersMap.removeLayerIfExists(this.segmentObjectLayerName);
            }

        }

        if (this.props.selectedImageIds != prevProps.selectedImageIds) {
            if (this.props.isLinkModeEnabled)
            {
                if (this.props.selectedImageIds.length > 0) {
                    this.openLayersMap.addSegmentHighlightLayer(this.segmentHighlightLayerName);
                }
                else {
                    this.openLayersMap.removeLayerIfExists(this.segmentHighlightLayerName);
                }
            }            
        }

        // Only really need to delete the high lighed layer if Linked mode is not enabled.
        if (this.props.isLinkModeEnabled != prevProps.isLinkModeEnabled && !this.props.isLinkModeEnabled) {            
            if (this.props.linkingResult != null && prevProps.linkingResult != null && this.props.linkingResult != prevProps.linkingResult) {
                if (this.props.linkingResult != null && this.props.linkingResult.result === 'linked') {

                    // A pole has been linked. Fade out the layer (which will have a single feature) then remove it
                    this.fadeOutAndRemoveSegmentHighlightLayer();
                }
                else {
                    this.openLayersMap.removeLayerIfExists(this.segmentHighlightLayerName);
                }
            }
        }

        if (this.props.refreshMapLayersFlag > prevProps.refreshMapLayersFlag) {
            //Refresh the layers
                for (var i = 0; i < imageSearchLayers.length; ++i) {
                    this.openLayersMap.updateWMSLayer(imageSearchLayers[i], { t: Date.now() });  
                    
            }
        }
    }

    getImageStatusIcon(poleId: string, active: boolean) {
        if (poleId && active) {
            return checkCircle;
        } else if (poleId && !active) {
            return inactiveAssetCircle;
        } else {
            return cancelCircle;
        }
    }

    getRGBAFromHexColour(hexColour: string,opactity:number) {
        if ( hexColour == null || hexColour.length != 6)
            return '';

        return 'rgba(' + this.getHexValFromNumber(hexColour,0,2) + ', ' + this.getHexValFromNumber(hexColour,2,4) + ',' + this.getHexValFromNumber(hexColour,4,6) + ',' + opactity + ')';
    }

    getHexValFromNumber(str: string, start:number, len:number) {
        return parseInt('0x' +  str.substring(start,len));
    }

    // Draw segment layer icons for Extent
    async getSegmentObjectLayerFeaturesForCurrentMapArea() {

        let segmentLayer = 'Segment';

        if ( this.openLayersMap == null || !this.openLayersMap.isLayerVisibleAtCurrentZoomLevel(segmentLayer))  
            return;

        let mapExtentWkt = this.openLayersMap.getWktForMapExtent();

        let segmentUrl = process.env.REACT_APP_VAA_API_URL + "segment/fetchByPolygon/";

        let result = await postWithAuthorisationHeader(segmentUrl,mapExtentWkt);
        if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null) {
            let featuresToCreate:any[] = [];

            result.data.forEach( (d:any) => featuresToCreate.push( 
                {
                    lat: d.lat,
                    lon: d.lon,
                    id: d.id,
                    properties: {
                        externalReferenceId:d.externalReferenceId
                    }
                } ));

            this.openLayersMap.addFeaturesToLayer(featuresToCreate, this.segmentObjectLayerName);

        } else if (result.status !== 200) {
            console.log('Error: Failed to get ' + segmentUrl + ' with status code ' + result.status);
        }
    }

    // ***** NOTE: Don't make the fade take too long. It will cause a face condition issue. It will delete the layer when the user has entered link mode again.
    fadeOutAndRemoveSegmentHighlightLayer() {
        // A pole has been linked.  Fade out the highlightlayer,which just has a single feature, as feedback to the user 

        if ( this.openLayersMap == null)
            return;

        let this_ = this;
        
        let intervalTime = 10; // ** Do not extend for too long or it will delete the layer when the user is linking again
        let totalDuration = 350;
        let numSteps = totalDuration / intervalTime;
        let step = numSteps;
        
        let highlightLayer :any =  this.openLayersMap.getLayer(this_.segmentHighlightLayerName);

        let olMap = this.openLayersMap;

        let segmentHighlightInterval:any = setInterval(() => {

            --step;
            let opactity = step / numSteps;

            let style = olMap.getSegmentHighlightLayerStyle(opactity);

            highlightLayer.setStyle(style);

        }, intervalTime);

        setTimeout(() => {
            if (segmentHighlightInterval != null) {
                olMap.removeLayerIfExists(this_.segmentHighlightLayerName);
                clearInterval(segmentHighlightInterval);
            }                
          }, totalDuration);   
    }

    // Handle event from OpenLayers
    async onDragBoxEnd(event: IMapDragBox) {
        if (this.props.imageSearch && this.props.imageSearch.searchId) {
            // Get the images inside the polygon
            let data = {
                Wkt: event.wkt,
                SearchId: this.props.imageSearch.searchId
            }

            let fetchUrl = process.env.REACT_APP_VAA_API_URL + "search/fetchIdsByPolygon/";
            let result = await postWithAuthorisationHeader(fetchUrl,data);
            if (result && result.status === 200 && result.data != null && result.data.length > 0) {
                if (this.props.onDragBoxEnd) {
                    // Send the images to VAA container to add it to the selectedImages list
                    this.props.onDragBoxEnd(result.data);
                }
            }
        }
    }

    async onMapSingleClick(event: IMapClick) {

        let minZoomForClickObject = 16;
        
        let imageSearchUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'ImageSearchResult');
        let segmentSearchUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'Segment');
        let shapefileSearchUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'Shapefiles');

        if ( event.zoom >= minZoomForClickObject) {
            
            let imageResults = [];
            let segmentResults: ISegment[] = [];
            let shapefileDataResults: IShapefileData[] = [];

            if (imageSearchUrl != null) {
                // The image search layer is visible so call the wms endpoint, get the first result, then fetch the full image search result

                // Get the image id from the map
                let result = await fetchWithAuthorisationHeader(imageSearchUrl.url);

                if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null) {

                    //Get the full image objects using ids
                    let ids: number[] = [];

                    result.data.filter((i: any) => !isNaN(i.id)).forEach((i: any) => ids.push(parseInt(i.id)));

                    let fetchUrl = process.env.REACT_APP_VAA_API_URL + "search/fetchBySessionImageIds";
                    let sessionImageResult = await postWithAuthorisationHeader(fetchUrl, ids);

                    if (sessionImageResult.status === 200 && sessionImageResult.data != null && sessionImageResult.data.length >= 1) {
                       // this.props.onMapSingleClickObjects(sessionImageResult.data, event.screenX, event.screenY);
                        imageResults = sessionImageResult.data; 
                    }
                    else {
                        console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + sessionImageResult.status);
                    }
                } else if (result.status !== 200) {
                    console.log('Error: Failed to get ' + imageSearchUrl.url + ' with status code ' + result.status);
                }
            }

            if (!this.props.isSelectModeEnabled && segmentSearchUrl != null) {
                // The segment layer is visible so call the wms endpoint and get the segment ids from the map

                let result = await fetchWithAuthorisationHeader(segmentSearchUrl.url);
                if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null) {
                    result.data.filter((i: any) => !isNaN(i.id)).forEach((i: any) => segmentResults.push(i));
                } else if (result.status !== 200) {
                    console.log('Error: Failed to get ' + segmentSearchUrl.url + ' with status code ' + result.status);
                }
            }

            if(!this.props.isSelectModeEnabled && shapefileSearchUrl != null){
                //the shapefile layer is visible so call the wms endpoint and get the shapefile data ids from the map
                let result = await fetchWithAuthorisationHeader(shapefileSearchUrl.url);
                if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null) {
                    result.data.filter((i: any) => !isNaN(i.id)).forEach((i: any) => shapefileDataResults.push(i));
                } else if (result.status !== 200) {
                    console.log('Error: Failed to get ' + shapefileSearchUrl.url + ' with status code ' + result.status);
                }
            }

            this.props.onMapSingleClickObjects(imageResults, segmentResults, shapefileDataResults, event.screenX, event.screenY);
            
        }

        if (this.props.selectedImageIds.length > 0 && this.props.isLinkModeEnabled && event.zoom >= minZoomForClickObject ) {
            // if a pole was clicked on then link it
            let clickedFeature: any = this.getSegmentHighlightFeatureAtPoint( [event.layerX,event.layerY] );
            if (clickedFeature != null) {
                let props = clickedFeature.getProperties();
                let segmentClicked = {
                    id : parseInt(clickedFeature.getId()),
                    externalReferenceId : props.externalReferenceId
                };
                this.props.onLinkPole(segmentClicked);
            }
        }

        if (this.props.onMapSingleClick) {
            // Echo the event out to a component event handler
            this.props.onMapSingleClick(event);
        }
    }

    onMapMoveStart(e:any) {
        this.props.onMapMoveStart(e);
    }

    // Fetch the data by extent and display selection icon
    async highlightSelectedImagesInCurrentMapExtent() {

        if ( this.openLayersMap == null || !this.props.isSelectModeEnabled || this.props.imageSearch == null || this.props.imageSearch.searchId == null )
            return;

        let data = {
            Wkt: this.openLayersMap.getWktForMapExtent(),
            SearchId: this.props.imageSearch.searchId
        }

        let result = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/fetchIdsByPolygon/",data);
        if (result && result.status === 200 && result.data != null ) {
            
            let imageIdsInMapExtent = result.data as number[];
            
            let selectedImageIds = this.props.selectedImageIds;
            
            let imageIdsToHighlight = imageIdsInMapExtent.filter(i=> selectedImageIds.includes(i));

            if (imageIdsToHighlight.length > 0) {

                result = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/fetchBasicImagesBySessionImageIds/", imageIdsToHighlight);
                if (result && result.status === 200 && result.data != null) {
                    this.openLayersMap.refreshSelectionIconLayer('selectionIconLayer', result.data );
                }
            }
            else {
                this.openLayersMap.refreshSelectionIconLayer('selectionIconLayer', []);
            }
        }
    }

    // Handle event from OpenLayers
    onMapMoveEnd(event: ICoordinate, zoom:number) {

        this.highlightSelectedImagesInCurrentMapExtent();

        if ( this.props.isLinkModeEnabled )
            this.getSegmentObjectLayerFeaturesForCurrentMapArea();

        if (this.props.onMapMoveEnd) {
            this.props.onMapMoveEnd(event, zoom);
        }

      // store zoom settings only if greater than 16, it will be used as new zoom level in focusobjectMap()
        if(zoom>clusterZoomLevel)
        {newZoomLevel = zoom;}
    }

    onMapPointerMove(event: any) {

        if (!this.props.isLinkModeEnabled || this.openLayersMap == null)
            return;

        // Link mode: if there is a feature under the mouse add a highlight feature

        let olMap = this.openLayersMap;

        let this_ = this;

        let objectlayer = olMap.getLayer(this_.segmentObjectLayerName);

        event.map.forEachFeatureAtPixel(event.pixel, function (feature:any,layer:any) {
        
            if (layer === objectlayer && !olMap != null) {
                let featureExists = false;
                olMap.getLayerFeatures(this_.segmentHighlightLayerName).forEach( (f:any) => {
                    if (f.getId() === feature.getId()) 
                        featureExists = true;
                })

                if (!featureExists) {
                    let mapCoord = feature.getGeometry().getCoordinates();

                    let coord = olMap.transformFromMapProjectionToDataProjection(mapCoord);

                    let featureToCreate = {
                        lat: coord[1],
                        lon: coord[0],
                        id: feature.getId(),
                        properties: feature.getProperties()
                    };

                    olMap.clearLayer(this_.segmentHighlightLayerName);
                    olMap.addFeaturesToLayer( [featureToCreate] , this_.segmentHighlightLayerName);

                    return true;
                }
            }
        }
       );
    }

    getSegmentHighlightFeatureAtPoint(point:number[]) {
        
        if (this.openLayersMap == null)
            return;

        let olMap = this.openLayersMap;
        let map = olMap.getMap();

        let this_ = this;

        let objectlayer = olMap.getLayer(this_.segmentHighlightLayerName);

        let theFeature = null;

        map.forEachFeatureAtPixel(point, function (feature:any,layer:any) {
            if (layer === objectlayer) {
                theFeature = feature;
                return true;
            }
        }
       );

       return theFeature;
    }

    async onTokenRefreshRequest() {
        let olMap = this.openLayersMap;

        let accessToken = await refreshToken();
        if (accessToken != null && olMap != null) {
            var authData = getAuthorizationData();
            
            olMap.updateAccessToken(authData.token, getTokenExpiryTime());            

            let layers = olMap.getWMSLayers();
            for (var i = 0; i < layers.length; ++i) {
                olMap.updateWMSLayer(layers[i].get('name'), { t: Date.now() }); 
            }
        } 
    }

    render() {

        return <div id="map" className={styles.map}> </div>;
    }
}
