import React, { useEffect, useState, useRef, useCallback } from 'react';
import styles from './MapControls.module.css';
import { CesiumViewerWrapper } from '../CesiumViewerWrapper/CesiumViewerWrapper';

interface IMapControlsProps {
    cesiumViewerWrapper: CesiumViewerWrapper;
    mousePosition: { x: number; y: number } | null;
    cesiumViewerInitialisedFlag: number;
}

const MapControls = (props: IMapControlsProps) => {

    const [cameraHeight, setCameraHeight] = useState('--');
    const [latitude, setLatitude] = useState('--');
    const [longitude, setLongitude] = useState('--');
    const [scaleWidth, setScaleWidth] = useState('0');
    const [scaleUnit, setScaleUnit] = useState('m');
    const lastCameraHeightRef = useRef<number | null>(null);
    const mousePositionRef = useRef<{ x: number; y: number } | null>(null);

    //Below state variables can be used for debugging
    //const [startEntity, setStartEntity] = useState();
    //const [endEntity, setEndEntity] = useState();

    const SCALE_LINE_WIDTH = 100;
    const HEIGHT_THRESHOLD = 5;
    const TILT_THRESHOLD = 125;

    let Cesium = (window as any).Cesium;
    const Ellipsoid = Cesium.Ellipsoid.WGS84;
  
    function toDMS(degrees: number, isLatitude: boolean): string {
        const absolute = Math.abs(degrees);
        const d = Math.floor(absolute); // Degrees
        const m = Math.floor((absolute - d) * 60); // Minutes
        const s = ((absolute - d - m / 60) * 3600).toFixed(2); // Seconds

        // Determine direction
        const direction = isLatitude
            ? degrees >= 0
                ? "N"
                : "S"
            : degrees >= 0
                ? "E"
                : "W";

        return `${d}\u00B0${m}'${s}" ${direction}`;
    }

    const isCameraTilted = useCallback((viewer: any): boolean  =>{
        const camera = viewer.scene.camera;
        const ellipsoid = viewer.scene.globe.ellipsoid;
        const cameraDirection = camera.direction;
        const cameraPosition = camera.position;

        // Get the surface normal (up vector) at the camera's position
        const cartographicPosition = Cesium.Cartographic.fromCartesian(cameraPosition);
        if (!cartographicPosition) {
            return false; // If the camera is not over the globe, it's not tilted
        }

        const surfaceNormal = ellipsoid.geodeticSurfaceNormal(cameraPosition);

        // Calculate the angle between the camera direction and surface normal
        const angleInRadians = Cesium.Cartesian3.angleBetween(cameraDirection, surfaceNormal);
        const angleInDegrees = Cesium.Math.toDegrees(angleInRadians);

        return angleInDegrees < TILT_THRESHOLD;

    }, [Cesium.Cartesian3, Cesium.Cartographic, Cesium.Math]);

    const calculateScaleWidth = (viewer: any, screenDistanceInPixels: number): { scaleWidth: string; unit: string } => {

        let geodesic = new Cesium.EllipsoidGeodesic();
        const scene = viewer.scene;

        // Center of map points
        let position = new Cesium.Cartesian2(scene.canvas.width / 2, scene.canvas.height / 2); 

        // Start and end points in screen space( horizontal line)
        let startScreenPoint = new Cesium.Cartesian2(position.x - screenDistanceInPixels / 2, position.y);
        let endScreenPoint = new Cesium.Cartesian2(position.x + screenDistanceInPixels / 2, position.y);


        // Use valid mousePosition if camera is tilted else use the above center of map for scale calculations
        if (isCameraTilted(viewer) && mousePositionRef.current) {
            let mousePosition = mousePositionRef.current;
            
            const mouseStartScreenPoint = new Cesium.Cartesian2(mousePosition.x - screenDistanceInPixels / 2, mousePosition.y);
            const mouseEndScreenPoint = new Cesium.Cartesian2(mousePosition.x + screenDistanceInPixels / 2, mousePosition.y);
           
            const isWithinBounds = (point: any) =>
                point.x > 0 && point.x <= scene.canvas.width && point.y > 0 && point.y <= scene.canvas.height;

            if (isWithinBounds(mouseStartScreenPoint)  && isWithinBounds(mouseEndScreenPoint)) {
                position = mousePosition;
                startScreenPoint = mouseStartScreenPoint;
                endScreenPoint = mouseEndScreenPoint;
            }
        }

        if (startScreenPoint !== undefined && endScreenPoint !== undefined) {

            //Convert to cartesian points
            const startCartesianWCPoint = viewer.camera.pickEllipsoid(startScreenPoint);
            const endCartesianWCPoint = viewer.camera.pickEllipsoid(endScreenPoint);


            if (!startCartesianWCPoint || !endCartesianWCPoint) {
                return { scaleWidth: '0', unit: '' }; // Invalid points
            }

            //Convert to cartographic points(lat, long)
            let startCartographicWCPoint = Ellipsoid.cartesianToCartographic(startCartesianWCPoint);
            let endCartographicWCPoint = Ellipsoid.cartesianToCartographic(endCartesianWCPoint);

            geodesic.setEndPoints(startCartographicWCPoint, endCartographicWCPoint);
            let groundDistance = geodesic.surfaceDistance.toFixed(2);

            let unit = "m";
            let scaleWidth = groundDistance;

            if (groundDistance >= 1000) {
                scaleWidth = (scaleWidth / 1000).toFixed(2);
                unit = "km";
            }

            // The below code is used while debugging to view the points selected for scale calculation on the viewer
            //if (startEntity) {
            //    props.cesiumViewerWrapper.removeEntity(startEntity);
            //}
            //if (endEntity) {
            //    props.cesiumViewerWrapper.removeEntity(endEntity);
            //}

            //const color = Cesium.Color.fromRandom();


            //const newStartEntity = props.cesiumViewerWrapper.addEntity({
            //    position: startCartesianWCPoint,
            //    point: { pixelSize: 10, color: color },
            //});

            //const newEndEntity = props.cesiumViewerWrapper.addEntity({
            //    position: endCartesianWCPoint,
            //    point: { pixelSize: 10, color: color },
            //});

            //setStartEntity(newStartEntity);
            //setEndEntity(newEndEntity);
            //props.cesiumViewerWrapper.cesiumViewer.scene.requestRender();
            //console.log("startCartographicWCPoint:", startCartographicWCPoint);
            //console.log("endCartographicWCPoint:", endCartographicWCPoint);
            //console.log("startScreenPoint:", startScreenPoint);
            //console.log("endScreenPoint:", endScreenPoint);
            //console.log("groundDistance:", groundDistance);

            return { scaleWidth, unit };
        }
        else {
            return { scaleWidth: '0', unit: '' };
        }
    }

    const updateScaleBar = () => {
        const camera = props.cesiumViewerWrapper.scene.camera;
        const cartographic = Cesium.Cartographic.fromCartesian(camera.positionWC);
        const currentHeight = cartographic.height;

        // Calculate scale only if height changes significantly
        if (lastCameraHeightRef.current !== null && Math.abs(currentHeight - lastCameraHeightRef.current) < HEIGHT_THRESHOLD) return;

        lastCameraHeightRef.current = currentHeight;
        const { scaleWidth, unit } = calculateScaleWidth(props.cesiumViewerWrapper, SCALE_LINE_WIDTH);
        setScaleWidth(scaleWidth);
        setScaleUnit(unit);
    }
 
    const updateCameraDetails = () => {
        const cartographic = Cesium.Cartographic.fromCartesian(
            props.cesiumViewerWrapper.camera.positionWC
        );
        setCameraHeight(cartographic.height.toFixed(0));

        const latitude = Cesium.Math.toDegrees(cartographic.latitude).toFixed(6);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude).toFixed(6);

        setLatitude(toDMS(latitude, true));
        setLongitude(toDMS(longitude, false));
    }


    // This code will be called every time the Cesium viewer is initialised
    useEffect(() => {
        mousePositionRef.current = props.mousePosition;
    }, [props.mousePosition]);

    // This code will be called every time the Cesium viewer is initialised
    useEffect(() => {
    
        if (props.cesiumViewerWrapper.hasViewer() ) {

            props.cesiumViewerWrapper.camera.moveEnd.addEventListener(updateCameraDetails);
            props.cesiumViewerWrapper.scene.camera.changed.addEventListener(updateScaleBar);

            // Trigger initial update for scale and camera details
            updateCameraDetails();
            updateScaleBar();

            return () => {
                props.cesiumViewerWrapper.scene.camera.changed.removeEventListener(updateCameraDetails);
                props.cesiumViewerWrapper.scene.camera.changed.removeEventListener(updateScaleBar);
            };
        }
    }, [props.cesiumViewerInitialisedFlag]);

    return (
        <div id="mapControlsContainer" className={styles.mapControlsContainer}>
            <div id="latLonHeightDisplay" className={styles.latLonHeightDisplay}>
                Camera: {cameraHeight} m,&nbsp; &nbsp;  {latitude} &nbsp; {longitude}
            </div>

            <div id="scaleContainer" className={styles.scaleContainer}>
                <div
                    id="scaleBar"
                    className={styles.scaleBar}
                    style={{
                        width: `${SCALE_LINE_WIDTH}px`, 
                    }}
                ></div>
                <span id="scaleLabel" className={styles.scaleLabel}>{scaleWidth} {scaleUnit}</span>
            </div>
        </div>
    );
};

export default MapControls;
