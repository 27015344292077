import React from 'react';

interface IProps {
    url: string | null;
}

export function UrlViewer(props: IProps) {

      let fullUrl = null;
      if ( props.url != null )
          fullUrl =  !props.url.toLowerCase().startsWith('http') ? 'https://' + props.url : props.url;

      return <div id={'urlviewer'} style={{height:'100%',width:'100%', background:'black',overflow: 'hidden !important'}} >   
        { fullUrl != null &&

            <iframe title="VIEWER" id="urlvieweriframe" style={{height:'100%',width:'100%',backgroundColor:'black',overflow: 'hidden !important'}} frameBorder={0} src={ fullUrl }></iframe>
        }
      </div>
}