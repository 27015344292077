import React, { useState, useEffect } from 'react';
import { fetchWithAuthorisationHeader } from "../../../../services/AuthenticationService";
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './TagFilter.module.css';
import TextField from '@material-ui/core/TextField';
import { Tooltip} from '@material-ui/core/';

const makeControlStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14
  },
  labelSelected: {
    fontSize: 14,
    color: '#F7B500'
  },
}));

interface ITagFilterProps {
  organisationId: number;
  onFilterChanged: Function;
  clearFilters: number;
}

interface ITag {
  name: string;
  isSelected: boolean;
}

export function TagFilter(props: ITagFilterProps) {

  const controlClasses = makeControlStyles();
  const initialTags: ITag[] = [];
  const [tags, setTags] = useState(initialTags);
  const [filteredTags, setFilteredTags] = useState(initialTags);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  // Handle when the filters have been cleared
  // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {

        if (props.clearFilters !== lastClearFilters) {
            tags.forEach(t => t.isSelected = false);
            setTags(tags.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);
        }

    }, [props.clearFilters, lastClearFilters, tags]);

    useEffect(() => {
        async function getTags() {
            try {
                var tags = initialTags;
                tags.forEach(t => t.isSelected = false);
                let selectedTags = tags.filter(s => s.isSelected && s.name !== 'Select All').map(s => s.name);
                props.onFilterChanged(selectedTags);
                if (props.organisationId > 0) {
                    var response = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "tag/distinctTags/" + props.organisationId);
                    const transformedTags = response.data.map((tag: ITag) => ({
                        name: tag,
                        isSelected: false
                    }));
                    setTags(transformedTags);
                    setFilteredTags(transformedTags);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        getTags();
     }, [props.organisationId]);

    function onSelectAll(selectAll: boolean) {
        
        filteredTags.forEach(t => t.isSelected = !selectAll);

        setTags(tags.slice());
        let selectedTagNames = tags.filter(u => u.isSelected).map(u => u.name);
        props.onFilterChanged(selectedTagNames);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(tag: ITag) {
        tag.isSelected = !tag.isSelected;
        setTags(tags.slice());
        let selectedTagNames = tags.filter(u => u.isSelected).map(u => u.name);
        props.onFilterChanged(selectedTagNames);
        syncSelectAll();
    };
  
    function syncSelectAll(){
        let selectedTags = tags.filter(u => u.isSelected);
        if (!selectAll && selectedTags.length == tags.length && tags.length > 0)
        {
            setSelectAll(true);
        } else if (selectAll && selectedTags.length < tags.length)
        {
            setSelectAll(false);
        }
    }
  
    function onSearchChange(value:string) {
        if(value==="")
        {
            syncSelectAll();
            setFilteredTags(tags);
        } else 
        {
            setFilteredTags(tags.filter(u => u.name.toLowerCase().includes(value.toLowerCase())));
        }
    };
  
    let tagNameCheckboxes: JSX.Element[] = [];
    filteredTags.forEach(tag => {
        tagNameCheckboxes.push(
            <FormControlLabel
                  key={tag.name}
                  classes={{ label: tag.isSelected ? controlClasses.labelSelected : controlClasses.label }}
                  control={<Checkbox checked={tag.isSelected} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onCheckboxChanged(tag)} name={tag.name} size={'small'} />}
                  label={tag.name}
            />
        );
    });

    let selectAllCheckbox = (
      <FormControlLabel
          key={"Select All"}
          classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
          control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
          label={"Select All"}
        />
    );
  
    return (
      <div className={styles.container}>
        <div className={styles.content}>
                {tags.length === 0 && 
              <div style={{width:125,height:20,padding:10}}>No tags available</div>
            }
                {tags.length > 0 &&
              <FormGroup>
                <TextField  style={{ color: '#F7B500' }} id="tag-filter" label="Search" onChange={(event) => onSearchChange(event.target.value)} />
                {selectAllCheckbox}
                <div className={styles.tagNames}>
                  {tagNameCheckboxes}
                </div>
              </FormGroup>
            }
        </div>
      </div>
    );
  }