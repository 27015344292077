import React, { useState, useEffect } from 'react'
import {
  Box,
  Badge,
  Chip,
  Tooltip,
  Avatar,
  Switch,
  FormControlLabel,
  FormControl
} from '@material-ui/core/'
import Grid from '@material-ui/core/Grid'
import { IMapLayerControlProps } from '../../models/IMapLayerControlProps'
import styles from './MapLayerControl.module.css'

export const MapLayerControl = (props: IMapLayerControlProps) => {
  const [isAvailable, setIsAvailable] = useState(props.isAvailable)
  const [isActive, setIsActive] = useState(props.isActive)
  const [label, setLabel] = useState(props.label)

  //method designed to be fired only when user checks/unchecks
  const triggerSetActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setIsActive(e.target.checked);
    //send back to layerlist
    props.onVisibilityToggle(props.group, props.label, e.target.checked)
  }

  const switchDom = (
    <div>
    <a style={{color: props.isActive && props.isAvailable ? 'rgba(249, 252, 255, 0.75)' : 'rgba(249,252,255,0.4)'}}>Off</a>
      <Switch
      disabled={!props.isAvailable}
      onChange={triggerSetActive}
       checked={props.isActive}
      color={'primary'}
      name='genericSwitch'
      inputProps={{ 'aria-label': 'primary checkbox' }}
    />
    <a style={{color: props.isActive && props.isAvailable ? 'rgba(249, 252, 255, 0.75)' : 'rgba(249,252,255,0.4)'}}>On</a>
    </div>
  )

  const layerAvailableStyle = {}

  useEffect(() => {
    //if (props.isActive      !=    isActive)      setIsActive(props.isActive);
    //incoming
    if (props.isAvailable !== isAvailable) setIsAvailable(props.isAvailable)
    if (props.label !== label) setLabel(props.label)
  }, [props.isActive, props.isAvailable, props.label])

  return (
    <Grid
    container
    justify="flex-start"
    className={styles.gridRow}
    spacing={1}>
        <Grid item 
        className={styles.content} 
        style={{ minWidth: "100%" }}
        >
            <Grid container justify="flex-start" spacing={3} className={styles.container}>
                {/* MAIN container */}
                <Grid item style={{"minWidth": "20px", paddingLeft: "20px"}}>
                <img
                    src={props.thumbnail} width="40px"></img>
                </Grid>
                <Grid item style={{"minWidth": "50%"}}>
                    <Box className={styles.bigProp} style={{"opacity": props.isAvailable ? "1" : "0.4"}}>
                            {props.label || "---"}</Box>
                        <Box className={styles.sessionLabel} style={{"opacity": props.isAvailable ? "0.75" : "0.4"}}>
                            {props.isActive ? "Layer Visible" : "Layer Hidden"}
                    </Box>
                </Grid>
                <Grid item>
                    {switchDom}
                </Grid>
            </Grid>
        </Grid>
    </Grid>);
}
    
