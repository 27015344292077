import React from 'react';
import { Card, Grid } from '@material-ui/core';

import styles from './MarkerMenu.module.css';
import { IUTMLocation } from '../../../models/IUTMLocation';

var utm = require('utm');

interface IMarkerMenuProps {
  mousePosition: any;
  clickLocation: any | null;
  isOpenInPopup: boolean;
}

interface IMarker {
  Longitude: number;
  Latitude: number;
  Altitude: string;
  Easting: number;
  Northing: number;
  Zone: string;
}

const MarkerMenu = (props: IMarkerMenuProps) => {
  let Cesium = (window as any).Cesium;

  const { mousePosition, clickLocation, isOpenInPopup } = props;

  if (mousePosition !== null && clickLocation !== null) {
    const marker = {} as IMarker;

    let cartographic = Cesium.Cartographic.fromCartesian(clickLocation);

    marker.Latitude = parseFloat(Cesium.Math.toDegrees(cartographic.latitude).toFixed(7));
    marker.Longitude = parseFloat(Cesium.Math.toDegrees(cartographic.longitude).toFixed(7));
      marker.Altitude = parseFloat(cartographic.height).toFixed(2) + 'm';

    let utmLocation: IUTMLocation = utm.fromLatLon(marker.Latitude, marker.Longitude);

    if (utmLocation) {
      marker.Easting = Number(utmLocation.easting.toFixed(2));
      marker.Northing = Number(utmLocation.northing.toFixed(2));
      marker.Zone = utmLocation.zoneNum + utmLocation.zoneLetter;
    }

    var attributes = [];

    let key: keyof IMarker;

    for (key in marker) {
      attributes.push(
        <>
          <Grid key={key} className={styles.attributes} item xs={6}>
            {key}
          </Grid>
          <Grid key={marker[key]} className={styles.attributes} item xs={6}>
            {marker[key]}
          </Grid>
        </>
      );
    }

    let topOffset = isOpenInPopup ? 24 : 64;
    let leftOffset = isOpenInPopup ? -88 : -80;

    return (
      <Card key={mousePosition} style={{ position: 'absolute', zIndex: 1000, top: mousePosition.y + topOffset, left: mousePosition.x + leftOffset, width: 180 }}>
        <Grid container className={styles.pointinfo}>
          <Grid style={{ textAlign: 'center' }} item xs={12}>
            Point Info
          </Grid>
          {attributes}
        </Grid>
      </Card>
    );
  } else {
    return <></>;
  }
};

export default MarkerMenu;
